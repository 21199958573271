// React
import React, { useState, useEffect } from 'react'

// React-dates
import ThemedStyleSheet from 'react-with-styles/lib/ThemedStyleSheet'
import aphroditeInterface from 'react-with-styles-interface-aphrodite/lib/aphroditeInterface'
import DefaultTheme from '../../assets/jss/material-dashboard-pro-react/themes/DefaultTheme'
import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'

// Moment
import moment from 'moment'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import Button from 'components/CustomButtons/Button'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'

// core components
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

// Components
import CustomTable from '../../components/Table/CustomTable'
// Axios
import axios from 'axios'

import styles from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle.js'

ThemedStyleSheet.registerInterface(aphroditeInterface)
ThemedStyleSheet.registerTheme(DefaultTheme)

const useStyles = makeStyles(styles)

const Dashboard = () => {
  const refDate = new Date()
  const currentYear = refDate.getFullYear().toString()
  const currentMonth =
    refDate.getMonth().toString().length === 1 &&
    refDate.getMonth().toString() < 9
      ? '0' + (refDate.getMonth() + 1).toString()
      : (refDate.getMonth() + 1).toString()

  let lastDay
  if (
    currentMonth === '01' ||
    currentMonth === '03' ||
    currentMonth === '05' ||
    currentMonth === '07' ||
    currentMonth === '08' ||
    currentMonth === '10' ||
    currentMonth === '12'
  ) {
    lastDay = '31'
  } else if (
    currentMonth === '04' ||
    currentMonth === '06' ||
    currentMonth === '09' ||
    currentMonth === '11'
  ) {
    lastDay = '30'
  } else if (currentMonth === '02') {
    lastDay = '28'
  }

  const [data, setData] = useState([])
  const [startDate, setStartDate] = useState(
    moment(currentYear + currentMonth + '01')
  )
  const [endDate, setEndDate] = useState(
    moment(currentYear + currentMonth + lastDay)
  )

  const [focusedInput, setFocusedInput] = useState(null)
  const [providers, setProviders] = useState([])
  const [tableData, setTableData] = useState([])
  const [sources, setSources] = useState([])
  const [formats, setFormats] = useState([])
  const [platforms, setPlatforms] = useState([])
  const [providerSelect, setProviderSelect] = useState('All Providers')
  const [sourceSelect, setSourceSelect] = useState('All Sources')
  const [formatSelect, setFormatSelect] = useState('All Formats')
  const [platformSelect, setPlatformSelect] = useState('All Platforms')
  const [viewDetails, setViewDetails] = useState(false)
  const loading = tableData.length > 0 ? false : true

  // Methods
  const handleChange = e => {
    if (e.target.name === 'providerSelect') {
      setProviderSelect(e.target.value)
    } else if (e.target.name === 'sourceSelect') {
      setSourceSelect(e.target.value)
    } else if (e.target.name === 'formatSelect') {
      setFormatSelect(e.target.value)
    } else if (e.target.name === 'platformSelect') {
      setPlatformSelect(e.target.value)
    }

    axios
      .get(
        `https://api.dt4ever.com/analytics/${moment(startDate._d).format(
          'YYYYMMDD'
        )}/${moment(endDate._d).format('YYYYMMDD')}`
      )
      .then(res =>
        setData(JSON.parse(res.data.data).sort((a, b) => b.date - a.date))
      )
      .catch(e => console.log(e))
  }

  const refreshStats = () => {
    axios
      .get(
        `https://api.dt4ever.com/analytics/${moment(startDate._d).format(
          'YYYYMMDD'
        )}/${moment(endDate._d).format('YYYYMMDD')}`
      )
      .then(res =>
        setData(JSON.parse(res.data.data).sort((a, b) => b.date - a.date))
      )
      .catch(e => console.log(e))
  }

  const capitalize = str => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const convertToCSV = arrayOfJson => {
    const replacer = (key, value) => (value === null ? '' : value) // specify how you want to handle null values here
    // Extract Table Headers
    const header = Object.keys(arrayOfJson[0])

    let csv = arrayOfJson.map(row =>
      header
        .map(fieldName => JSON.stringify(row[fieldName], replacer))
        .join(',')
    )
    // Add Headers before mapped data and capitalize first letter
    csv.unshift(header.map(el => capitalize(el)).join(','))
    csv = csv.join('\r\n')
    return csv
  }

  const exportCSV = () => {
    console.log('TABLE DATA', tableData)
    tableData.forEach(cell => {
      Object.keys(cell).forEach((key, index) => {
        if (typeof cell[key] === 'string') {
          cell[key] = cell[key].replace(',', ' ')
        }
        if (
          key !== 'date' &&
          key !== 'format' &&
          key !== 'platform' &&
          key !== 'provider' &&
          key !== 'source' &&
          key !== 'prints'
        ) {
          delete cell[key]
        }
      })
    })
    const csv = convertToCSV(tableData)
    // const data = Buffer.from(csv, 'utf8')
    const link = document.createElement('a')
    link.href = 'data:text/csv;charset=utf-8,' + escape(csv)
    link.download = 'statistics.csv'
    link.click()
  }

  // Effects
  useEffect(() => {
    axios
      .get(
        `https://api.dt4ever.com/analytics/${moment(startDate._d).format(
          'YYYYMMDD'
        )}/${moment(endDate._d).format('YYYYMMDD')}`
      )
      .then(res => {
        setData(JSON.parse(res.data.data).sort((a, b) => b.date - a.date))
      })
      .catch(e => console.log(e))
  }, [startDate, endDate])

  useEffect(() => {
    setProviders([...new Set(data.map(doc => doc.provider))])
    setSources([...new Set(data.map(doc => doc.source))])
    setFormats([...new Set(data.map(doc => doc.format))])
    setPlatforms([...new Set(data.map(doc => doc.platform))])
  }, [data])

  useEffect(() => {
    if (viewDetails) {
      let newData = data

      if (providerSelect && providerSelect !== 'All Providers') {
        newData = newData.filter(doc => doc.provider === providerSelect)
      }
      if (sourceSelect && sourceSelect !== 'All Sources') {
        newData = newData.filter(doc => doc.source === sourceSelect)
      }
      if (formatSelect && formatSelect !== 'All Formats') {
        newData = newData.filter(doc => doc.format === formatSelect)
      }
      if (platformSelect && platformSelect !== 'All Platforms') {
        newData = newData.filter(doc => doc.platform === platformSelect)
      }

      setTableData(newData)
    } else if (!viewDetails) {
      let newData = data

      const dates = [...new Set(newData.map(doc => doc.date))]
      if (providerSelect && providerSelect !== 'All Providers') {
        newData = newData.filter(doc => doc.provider === providerSelect)
      }
      if (sourceSelect && sourceSelect !== 'All Sources') {
        newData = newData.filter(doc => doc.source === sourceSelect)
      }
      if (formatSelect && formatSelect !== 'All Formats') {
        newData = newData.filter(doc => doc.format === formatSelect)
      }
      if (platformSelect && platformSelect !== 'All Platforms') {
        newData = newData.filter(doc => doc.platform === platformSelect)
      }

      const totals = []
      dates.forEach(date => {
        const totalPrints = newData
          .filter(doc => doc.date === date)
          .reduce(
            (sum, doc) => (doc.prints ? (sum += doc.prints) : (sum += 0)),
            0
          )
        const totalClicks = newData
          .filter(doc => doc.date === date)
          .reduce(
            (sum, doc) => (doc.clicks ? (sum += doc.clicks) : (sum += 0)),
            0
          )

        totals.push({
          date,
          provider: providerSelect,
          source: sourceSelect,
          format: formatSelect,
          platform: platformSelect,
          affiliate: 'none',
          utmSource: 'none',
          utmMedium: 'none',
          utmCampaign: 'none',
          clicks: totalClicks,
          prints: totalPrints
        })
      })

      setTableData(totals)
    }
  }, [
    providerSelect,
    sourceSelect,
    formatSelect,
    platformSelect,
    startDate,
    endDate,
    data,
    viewDetails
  ])

  const classes = useStyles()
  return (
    <div>
      <Card>
        <CardBody className={classes.selectContainer}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={4}>
              <label className={classes.datePickerLabel}>
                Select Date Range
              </label>
              <DateRangePicker
                startDate={startDate}
                startDateId='start-date'
                endDate={endDate}
                endDateId='end-date'
                onDatesChange={({ startDate, endDate }) => {
                  if (startDate > endDate) {
                    setStartDate(startDate)
                    setEndDate(startDate)
                  } else {
                    setStartDate(startDate)
                    setEndDate(endDate)
                  }
                }}
                isOutsideRange={() => false}
                focusedInput={focusedInput}
                onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                displayFormat='DD-MM-YYYY'
                showClearDates={true}
                reopenPickerOnClearDates={true}
                minimumNights={0}
              />
            </GridItem>
            <GridItem
              xs={12}
              sm={12}
              md={12}
              lg={8}
              style={{ marginTop: '12px' }}
            >
              <GridContainer>
                <GridItem xs={12} sm={6} md={3} lg={3}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor='provider-select'
                      className={classes.selectLabel}
                    >
                      Select Provider
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={providerSelect}
                      onChange={handleChange}
                      inputProps={{
                        name: 'providerSelect',
                        id: 'provider-select'
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Select Provider
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem
                        }}
                        value='All Providers'
                      >
                        All Providers
                      </MenuItem>
                      {providers &&
                        providers.map((provider, index) => (
                          <MenuItem
                            key={index}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={provider}
                          >
                            {provider}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3} lg={3}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor='source-select'
                      className={classes.selectLabel}
                    >
                      Select Source
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={sourceSelect}
                      onChange={handleChange}
                      inputProps={{
                        name: 'sourceSelect',
                        id: 'source-select'
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Select Source
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem
                        }}
                        value='All Sources'
                      >
                        All Sources
                      </MenuItem>
                      {sources &&
                        sources.map((source, index) => (
                          <MenuItem
                            key={index}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={source}
                            disabled={
                              providerSelect &&
                              providerSelect !== 'All Providers'
                                ? !data
                                    .filter(
                                      doc => doc.provider === providerSelect
                                    )
                                    .map(doc => doc.source)
                                    .includes(source)
                                : false
                            }
                          >
                            {source}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3} lg={3}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor='format-select'
                      className={classes.selectLabel}
                    >
                      Select Format
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={formatSelect}
                      onChange={handleChange}
                      inputProps={{
                        name: 'formatSelect',
                        id: 'format-select'
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Select Format
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem
                        }}
                        value='All Formats'
                      >
                        All formats
                      </MenuItem>
                      {formats &&
                        formats.map((format, index) => (
                          <MenuItem
                            key={index}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={format}
                            disabled={
                              viewDetails
                                ? (providerSelect &&
                                    providerSelect !== 'All Providers') ||
                                  (sourceSelect &&
                                    sourceSelect !== 'All Sources')
                                  ? sourceSelect
                                    ? !data
                                        .filter(
                                          doc =>
                                            doc.provider === providerSelect &&
                                            doc.source === sourceSelect
                                        )
                                        .map(doc => doc.format)
                                        .includes(format)
                                    : !data
                                        .filter(
                                          doc => doc.provider === providerSelect
                                        )
                                        .map(doc => doc.format)
                                        .includes(format)
                                  : false
                                : false
                            }
                          >
                            {format}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={6} md={3} lg={3}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor='platform-select'
                      className={classes.selectLabel}
                    >
                      Select Platform
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu
                      }}
                      classes={{
                        select: classes.select
                      }}
                      value={platformSelect}
                      onChange={handleChange}
                      inputProps={{
                        name: 'platformSelect',
                        id: 'platform-select'
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{
                          root: classes.selectMenuItem
                        }}
                      >
                        Select Platform
                      </MenuItem>
                      <MenuItem
                        classes={{
                          root: classes.selectMenuItem
                        }}
                        value='All Platforms'
                      >
                        All Platforms
                      </MenuItem>
                      {platforms &&
                        platforms.map((platform, index) => (
                          <MenuItem
                            key={index}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelected
                            }}
                            value={platform}
                          >
                            {platform}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={12}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  color='transparent'
                  onClick={refreshStats}
                  style={{
                    color: '#0d47a1',
                    fontSize: '0.8rem',
                    marginTop: '1.5rem',
                    padding: '0.5rem',
                    border: '1.2px solid #0d47a1',
                    borderRadius: '8px'
                  }}
                >
                  Refresh Stats
                </Button>
              </div>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <GridContainer justify='flex-end'>
        <GridItem xs={3}>
          <div style={{ float: 'right' }}>
            <FormControlLabel
              control={
                <Switch
                  checked={viewDetails}
                  onChange={e => {
                    viewDetails ? setViewDetails(false) : setViewDetails(true)
                  }}
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    thumb: classes.switchIcon,
                    track: classes.switchBar
                  }}
                />
              }
              classes={{
                label: classes.label
              }}
              color='primary'
              label='Detailed View'
            />
          </div>
        </GridItem>
      </GridContainer>

      <CustomTable
        tableData={tableData}
        provider={providerSelect}
        viewDetails={viewDetails}
        loading={loading}
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          color='transparent'
          onClick={exportCSV}
          style={{
            color: '#0d47a1',
            fontSize: '0.8rem',
            marginTop: '1.5rem',
            padding: '0.5rem',
            border: '1.2px solid #0d47a1',
            borderRadius: '8px'
          }}
        >
          EXPORT CSV
        </Button>
      </div>
    </div>
  )
}

export default Dashboard
