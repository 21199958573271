import React, { useState } from 'react'

// Material UI components
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Slide from '@material-ui/core/Slide'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

// core components
import ImageUpload from 'components/CustomUpload/ImageUpload.js'
import Button from 'components/CustomButtons/Button.js'
import GridContainer from 'components/Grid/GridContainer.js'
import GridItem from 'components/Grid/GridItem.js'
import CustomInput from 'components/CustomInput/CustomInput.js'

// Material Icons
import Close from '@material-ui/icons/Close'

// Formik && YUP
import { Formik, Form } from 'formik'

// Axios
import axios from 'axios'

// styles
import styles from 'assets/jss/material-dashboard-pro-react/modalStyle.js'

const useStyles = makeStyles(styles)

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='down' ref={ref} {...props} />
})

const NewProduct = props => {
  const [error, setError] = useState('')
  const formats = ['interstitial', 'popUnder', 'preRoll', 'banner']
  const platforms = ['mobile', 'desktop']
  const zones = [
    'inplayer',
    'footer01',
    'footer02',
    'footer03',
    'home01',
    'home02',
    'listado01',
    'listado02',
    'video01',
    'video02',
    'undervideo'
  ]
  const bannerTypes = ['internal', 'external']
  // Styles
  const classes = useStyles()

  // Methods
  const handleClose = () => {
    props.setOpen(false)
  }

  const escapeRegExp = text => {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
  }

  const handleSubmit = values => {
    props.setOpen(false)
    if (values.format === 'preRoll') {
      const newProduct = {
        name: values.name,
        provider: values.provider,
        format: values.format,
        platform: values.platform,
        trigger: Number(values.trigger),
        affiliate: values.affiliate,
        url: values.url,
        originURL: values.originURL
      }

      props.updateAlert()

      axios
        .post('https://api.dt4ever.com/products', newProduct)
        .then(res => props.refreshProducts())
        .catch(e => console.log(e))
    } else if (values.format === 'banner') {
      const newProduct = {
        name: values.name,
        provider: values.provider,
        format: values.format,
        platform: values.platform,
        trigger: Number(values.trigger),
        zone: values.zone,
        bannerType: values.bannerType,
        bannerImage: values.bannerImage,
        bannerAlt: values.bannerAlt,
        url: values.url,
        body: values.body
      }

      props.updateAlert()

      axios
        .post('https://api.dt4ever.com/products', newProduct)
        .then(res => props.refreshProducts())
        .catch(e => console.log(e))
    } else {
      let newProductBody
      if (values.format === 'popUnder' && values.popUnderType === 'redirect') {
        newProductBody = `dt4URL = "${values.body}"`
      } else if (
        values.format === 'popUnder' &&
        values.popUnderType === 'function'
      ) {
        newProductBody = escapeRegExp(values.body)
      } else {
        newProductBody = values.body
      }

      const newProduct = {
        name: values.name,
        provider: values.provider,
        format: values.format,
        platform: values.platform,
        trigger: Number(values.trigger),
        timeLimit: values.timeLimit / 24 / 60,
        url: values.url,
        popUnderType: values.popUnderType,
        interstitialType: values.interstitialType,
        body: newProductBody
      }

      props.updateAlert()

      axios
        .post('https://api.dt4ever.com/products', newProduct)
        .then(res => props.refreshProducts())
        .catch(e => console.log(e))
    }
  }

  const isValidUrl = string => {
    try {
      new URL(string)
    } catch (_) {
      return false
    }
    return true
  }

  return (
    <div>
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal
        }}
        open={props.open}
        transition={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby='modal-slide-title'
        aria-describedby='modal-slide-description'
      >
        <DialogTitle
          id='classic-modal-slide-title'
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key='close'
            aria-label='Close'
            color='transparent'
            onClick={handleClose}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>NEW PRODUCT</h4>
        </DialogTitle>
        <DialogContent
          id='modal-slide-description'
          className={classes.modalBody}
        >
          <Formik
            initialValues={{
              name: '',
              provider: '',
              format: '',
              popUnderType: null,
              interstitialType: null,
              zone: null,
              bannerType: null,
              bannerImage: null,
              bannerAlt: null,
              platform: '',
              trigger: 0,
              url: '',
              body: '',
              timeLimit: 0,
              affiliate: '',
              originURL: ''
            }}
            onSubmit={(values, { resetForm }) => {
              handleSubmit(values)
              resetForm({})
            }}
            enableReinitialize
          >
            {({ values, setFieldValue, resetForm }) => (
              <Form>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Name'
                      id='name'
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: 'name',
                        value: values.name,
                        onChange: e => {
                          setError('')
                          setFieldValue('name', e.target.value)
                        }
                      }}
                    />
                    {error && values.name === ''
                      ? (
                        <div className={classes.errors}>Required *</div>
                        )
                      : null}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText='Provider'
                      id='provider'
                      formControlProps={{
                        fullWidth: true
                      }}
                      inputProps={{
                        name: 'provider',
                        value: values.provider,
                        onChange: e => {
                          setError('')
                          setFieldValue('provider', e.target.value)
                        }
                      }}
                    />
                    {error && values.provider === ''
                      ? (
                        <div className={classes.errors}>Required *</div>
                        )
                      : null}
                  </GridItem>

                  <GridItem xs={12} sm={12} md={3}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor='format'
                        className={classes.selectLabel}
                      >
                        Format
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={values.format}
                        onChange={e => {
                          setError('')
                          setFieldValue('format', e.target.value)
                          if (e.target.value === 'popUnder') {
                            setFieldValue(
                              'url',
                              'https://dt4ever.com/scripts/popunder.js'
                            )
                          } else {
                            setFieldValue('url', '')
                          }
                        }}
                        inputProps={{
                          name: 'format',
                          id: 'formatSelect'
                        }}
                      >
                        {formats.map((doc, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={doc}
                              classes={{
                                root: classes.selectMenuItem
                              }}
                            >
                              {doc}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                    {error && values.format === ''
                      ? (
                        <div className={classes.errors}>Required *</div>
                        )
                      : null}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <FormControl
                      fullWidth
                      className={classes.selectFormControl}
                    >
                      <InputLabel
                        htmlFor='format'
                        className={classes.selectLabel}
                      >
                        Platform
                      </InputLabel>
                      <Select
                        MenuProps={{
                          className: classes.selectMenu
                        }}
                        classes={{
                          select: classes.select
                        }}
                        value={values.platform}
                        onChange={e => {
                          setError('')
                          setFieldValue('platform', e.target.value)
                        }}
                        inputProps={{
                          name: 'platform',
                          id: 'platformSelect'
                        }}
                      >
                        {platforms.map((doc, index) => {
                          return (
                            <MenuItem
                              key={index}
                              value={doc}
                              classes={{
                                root: classes.selectMenuItem
                              }}
                            >
                              {doc}
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                    {error && values.platform === ''
                      ? (
                        <div className={classes.errors}>Required *</div>
                        )
                      : null}
                  </GridItem>
                  {values.format === 'banner'
                    ? (
                      <GridItem xs={12} sm={12} md={3}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor='zone'
                            className={classes.selectLabel}
                          >
                            Zone
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={values.zone}
                            onChange={e => {
                              setError('')
                              setFieldValue('zone', e.target.value)
                            }}
                            inputProps={{
                              name: 'zone',
                              id: 'zoneSelect'
                            }}
                          >
                            {zones.map((doc, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={doc}
                                  classes={{
                                    root: classes.selectMenuItem
                                  }}
                                >
                                  {doc}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                        {error && values.platform === ''
                          ? (
                            <div className={classes.errors}>Required *</div>
                            )
                          : null}
                      </GridItem>
                      )
                    : null}
                  {values.format === 'banner'
                    ? (
                      <GridItem xs={12} sm={12} md={3}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor='bannerType'
                            className={classes.selectLabel}
                          >
                            Banner Type
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={values.bannerType}
                            onChange={e => {
                              setError('')
                              setFieldValue('bannerType', e.target.value)
                            }}
                            inputProps={{
                              name: 'bannerType',
                              id: 'bannerTypeSelect'
                            }}
                          >
                            {bannerTypes.map((doc, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={doc}
                                  classes={{
                                    root: classes.selectMenuItem
                                  }}
                                >
                                  {doc}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                        {error && values.bannerType === ''
                          ? (
                            <div className={classes.errors}>Required *</div>
                            )
                          : null}
                      </GridItem>
                      )
                    : null}
                  {values.format !== 'banner'
                    ? (
                      <GridItem xs={12} md={3}>
                        <CustomInput
                          labelText='Trigger(Clicks)'
                          id='triggerInput#1'
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            name: 'trigger',
                            type: 'number',
                            value: values.trigger,
                            onChange: e => {
                              setError('')
                              setFieldValue('trigger', e.target.value)
                            }
                          }}
                        />
                        {error && values.trigger === ''
                          ? (
                            <div className={classes.errors}>Required *</div>
                            )
                          : null}
                      </GridItem>
                      )
                    : null}

                  {values.format === 'banner' &&
                    values.bannerType === 'internal'
                    ? (
                      <GridItem xs={12}>
                        <GridContainer>
                          <GridItem xs={12} sm={6}>
                            <ImageUpload
                              setFieldValue={setFieldValue}
                              setError={setError}
                              values={values}
                            />
                          </GridItem>
                          <GridItem xs={12} sm={6}>
                            {values.format === 'banner'
                              ? (
                                <GridItem
                                  xs={12}
                                  sm={12}
                                  style={{ marginTop: '2rem' }}
                                >
                                  <CustomInput
                                    labelText='Alt'
                                    id='alt'
                                    formControlProps={{
                                      fullWidth: true
                                    }}
                                    inputProps={{
                                      name: 'alt',
                                      value: values.bannerAlt,
                                      onChange: e => {
                                        setError('')
                                        setFieldValue('bannerAlt', e.target.value)
                                      }
                                    }}
                                  />
                                  {error && values.platform === ''
                                    ? (
                                      <div className={classes.errors}>Required *</div>
                                      )
                                    : null}
                                </GridItem>
                                )
                              : null}
                          </GridItem>
                        </GridContainer>

                        {error && values.bannerImage === ''
                          ? (
                            <div className={classes.errors}>Required *</div>
                            )
                          : null}
                      </GridItem>
                      )
                    : null}
                  {values.format !== 'preRoll' && values.format !== 'banner'
                    ? (
                      <GridItem xs={12} md={3}>
                        <CustomInput
                          labelText='Time Limit(Mins)'
                          id='timeLimitInput'
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            name: 'timeLimit',
                            type: 'number',
                            value: values.timeLimit,
                            onChange: e => {
                              setError('')
                              setFieldValue('timeLimit', e.target.value)
                            }
                          }}
                        />
                        {error && values.timeLimit === ''
                          ? (
                            <div className={classes.errors}>Required *</div>
                            )
                          : null}
                      </GridItem>
                      )
                    : null}
                  {values.format === 'preRoll'
                    ? (
                      <GridItem xs={12} md={3}>
                        <CustomInput
                          labelText='Exception'
                          id='affiliate'
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            name: 'affiliate',
                            type: 'text',
                            value: values.affiliate,
                            onChange: e => {
                              setError('')
                              setFieldValue('affiliate', e.target.value)
                            }
                          }}
                        />
                        {error && values.affiliate === ''
                          ? (
                            <div className={classes.errors}>Required *</div>
                            )
                          : null}
                      </GridItem>
                      )
                    : null}

                  <GridItem xs={12}>
                    <GridContainer>
                      {values.format !== 'banner'
                        ? (
                          <GridItem
                            xs={
                              values.format === 'popUnder' ||
                                values.format === 'interstitial'
                                ? 9
                                : 12
                            }
                          >
                            <CustomInput
                              labelText={
                                values.format === 'preRoll' ? 'URL Vast' : 'URL'
                              }
                              id='url#1'
                              formControlProps={{
                                fullWidth: true
                              }}
                              inputProps={{
                                name: 'url',
                                value: values.url,
                                onChange: e => {
                                  setError('')
                                  setFieldValue('url', e.target.value)
                                },
                                disabled: values.format === 'popUnder'
                              }}
                            />
                          </GridItem>
                          )
                        : null}

                      {values.format === 'popUnder'
                        ? (
                          <GridItem xs={3}>
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <InputLabel
                                htmlFor='format'
                                className={classes.selectLabel}
                              >
                                PopUnder Type
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu
                                }}
                                classes={{
                                  select: classes.select
                                }}
                                value={values.popUnderType}
                                onChange={e => {
                                  setError('')
                                  setFieldValue('popUnderType', e.target.value)
                                }}
                                inputProps={{
                                  name: 'popUnderType',
                                  id: 'popUnderType'
                                }}
                              >
                                {['redirect', 'function', 'script'].map(
                                  (doc, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        value={doc}
                                        classes={{
                                          root: classes.selectMenuItem
                                        }}
                                      >
                                        {doc}
                                      </MenuItem>
                                    )
                                  }
                                )}
                              </Select>
                            </FormControl>
                          </GridItem>
                          )
                        : null}
                      {values.format === 'interstitial'
                        ? (
                          <GridItem xs={3}>
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <InputLabel
                                htmlFor='format'
                                className={classes.selectLabel}
                              >
                                Interstitial Type
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu
                                }}
                                classes={{
                                  select: classes.select
                                }}
                                value={values.interstitialType}
                                onChange={e => {
                                  setError('')
                                  setFieldValue(
                                    'interstitialType',
                                    e.target.value
                                  )
                                  console.log(values.interstitialType)
                                }}
                                inputProps={{
                                  name: 'interstitialType',
                                  id: 'interstitialType'
                                }}
                              >
                                {['iframe', 'script'].map((doc, index) => {
                                  return (
                                    <MenuItem
                                      key={index}
                                      value={doc}
                                      classes={{
                                        root: classes.selectMenuItem
                                      }}
                                    >
                                      {doc}
                                    </MenuItem>
                                  )
                                })}
                              </Select>
                            </FormControl>
                          </GridItem>
                          )
                        : null}
                    </GridContainer>

                    {error && values.url === ''
                      ? (
                        <div className={classes.errors}>Required *</div>
                        )
                      : null}
                    {error && values.url !== '' && !isValidUrl(values.url)
                      ? (
                        <div className={classes.errors}>
                          Invalid link. Please remember to add http:// or https://
                          at the beginning of your url
                        </div>
                        )
                      : null}
                  </GridItem>
                  {values.format !== 'preRoll'
                    ? (
                      <GridItem xs={12}>
                        <CustomInput
                          labelText={
                            values.format === 'popUnder'
                              ? values.popUnderType === 'redirect'
                                  ? 'Popunder URL'
                                  : values.popUnderType === 'function'
                                    ? 'Function Body'
                                    : 'Script src'
                              : values.format === 'banner'
                                ? values.bannerType === 'internal'
                                    ? 'Banner Redirect URL'
                                    : 'Banner Body'
                                : 'Attached Variables'
                          }
                          id='body'
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            name: 'body',
                            value: values.body,
                            onChange: e => {
                              setError('')
                              setFieldValue('body', e.target.value)
                            }
                          }}
                        />
                        {error && values.body === ''
                          ? (
                            <div className={classes.errors}>Required *</div>
                            )
                          : null}
                        {error &&
                          values.body !== '' &&
                          !isValidUrl(values.body) &&
                          values.format === 'popUnder'
                          ? (
                            <div className={classes.errors}>
                              Invalid link. Please remember to add http:// or
                              https:// at the beginning of your url
                            </div>
                            )
                          : null}
                      </GridItem>
                      )
                    : null}

                  <GridItem xs={12} md={10} style={{ marginTop: '0.5rem' }}>
                    {error
                      ? (
                        <div className={classes.errors}>{error}</div>
                        )
                      : null}
                  </GridItem>
                  <GridItem xs={12} md={2}>
                    <Button
                      type='submit'
                      color='primary'
                      fullWidth
                      onClick={e => {
                        e.preventDefault()
                        if (values.format === 'preRoll') {
                          if (
                            values.name === '' ||
                            values.provider === '' ||
                            values.url === '' ||
                            values.format === '' ||
                            values.platform === ''
                          ) {
                            setError('Please fill in all form fields')
                          } else if (!isValidUrl(values.url)) {
                            setError(
                              'Please provide a valid link to the URL Field'
                            )
                          } else {
                            handleSubmit(values)
                            setError('')
                            resetForm()
                          }
                        } else if (values.format === 'banner') {
                          if (
                            values.name === '' ||
                            values.provider === '' ||
                            values.format === '' ||
                            values.platform === ''
                          ) {
                            setError('Please fill in all form fields')
                          } else {
                            handleSubmit(values)
                            setError('')
                            resetForm()
                          }
                        } else {
                          if (
                            values.name === '' ||
                            values.provider === '' ||
                            values.url === '' ||
                            values.format === '' ||
                            values.platform === ''
                          ) {
                            setError('Please fill in all form fields')
                          } else if (!isValidUrl(values.url)) {
                            setError(
                              'Please provide a valid link to the URL Field'
                            )
                          } else {
                            handleSubmit(values)
                            setError('')
                            resetForm()
                          }
                        }
                      }}
                    >
                      Save
                    </Button>
                  </GridItem>
                </GridContainer>
              </Form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions
          className={classes.modalFooter + ' ' + classes.modalFooterCenter}
        />
      </Dialog>
    </div>
  )
}

export default NewProduct
