import Dashboard from 'views/Dashboard/Dashboard.js'
import Products from 'views/Products/Products.js'
import Campaigns from 'views/Campaigns/Campaigns.js'
import Logout from 'views/Pages/Logout.js'

// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard'
import DevicesIcon from '@material-ui/icons/Devices'
import WorkIcon from '@material-ui/icons/Work'
import ExitToApp from '@material-ui/icons/ExitToApp'

// Pages
import LoginPage from 'views/Pages/LoginPage'

const dashRoutes = [
  {
    path: '/dashboard',
    name: 'Stats',
    rtlName: 'Stats',
    icon: DashboardIcon,
    component: Dashboard,
    layout: '/admin'
  },
  {
    path: '/products',
    name: 'Products',
    rtlName: 'Products',
    icon: DevicesIcon,
    component: Products,
    layout: '/admin'
  },
  {
    path: '/campaigns',
    name: 'Campaigns',
    rtlName: 'Campaigns',
    icon: WorkIcon,
    component: Campaigns,
    layout: '/admin'
  },
  {
    path: '/login',
    name: 'Login',
    rltName: 'Login',
    component: LoginPage,
    layout: '/auth',
    invisible: true
  },
  {
    path: '/logout',
    name: 'Logout',
    icon: ExitToApp,
    rltName: 'Logout',
    component: Logout,
    layout: '/auth',
    logout: true
  }
]
export default dashRoutes
