import React, { useState, useEffect } from 'react'

// React-table
import ReactTable from 'react-table'

// Components
import NewProduct from '../../components/NewProduct/NewProduct'
import EditProduct from '../../components/EditProduct/EditProduct'

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Danger from 'components/Typography/Danger'

// Icons
import EditIcon from '@material-ui/icons/Edit'
import DeleteForever from '@material-ui/icons/DeleteForever'

// Sweet Alert
import SweetAlert from 'react-bootstrap-sweetalert'

// styles
import alertStyles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle'

// Axios
import axios from 'axios'

import { infoColor } from 'assets/jss/material-dashboard-pro-react.js'

const useAlertStyles = makeStyles(alertStyles)

const Products = () => {
  const [alert, setAlert] = useState(null)
  const [ads, setAds] = useState([])
  const [products, setProducts] = useState([])
  const [distributed, setDistributed] = useState([])
  const [selectedProduct, setSelectedProduct] = useState({})
  const [newProductScreen, setNewProductScreen] = useState(false)
  const [editProductScreen, setEditProductScreen] = useState(false)
  const [campaignsUpdate, setCampaignsUpdate] = useState(null)
  const [productToUpdate, setProductToUpdate] = useState(null)

  // Styles
  const alertClasses = useAlertStyles()

  // Methods
  const handleClick = (e, row) => {
    if (e.currentTarget.value === 'edit') {
      setSelectedProduct(row)

      setEditProductScreen(true)
    } else if (e.currentTarget.value === 'delete') {
      setAlert(
        <SweetAlert
          danger
          style={{
            display: 'block',
            marginTop: '-100px'
          }}
          title='Delete Product'
          onConfirm={() => {
            axios
              .delete(`https://api.dt4ever.com/products/${row.name}`)
              .then(res => {
                refreshProducts()
                updateAlert()
              })
              .catch(e => console.log(e))
          }}
          onCancel={() => hideAlert()}
          confirmBtnCssClass={alertClasses.button + ' ' + alertClasses.success}
          cancelBtnCssClass={alertClasses.button + ' ' + alertClasses.danger}
          showCancel
          confirmBtnText='YES'
          cancelBtnText='NO'
        >
          Are you sure you want to remove this product ?
        </SweetAlert>
      )
    }
  }
  const hideAlert = () => {
    setAlert(null)
  }
  const refreshProducts = () => {
    axios
      .get('https://api.dt4ever.com/products')
      .then(res =>
        setProducts(
          res.data
            .map(doc => {
              doc.timeLimit = (doc.timeLimit * 24 * 60).toFixed(0)
              return doc
            })
            .sort((a, b) => {
              const textA = a.format
              const textB = b.format
              return textA < textB ? -1 : textA > textB ? 1 : 0
            })
        )
      )
      .catch(e => console.log(e))
  }
  const updateAlert = () => {
    setAlert(
      <SweetAlert
        success
        style={{
          display: 'block',
          marginTop: '-100px'
        }}
        title='Products Updated'
        onConfirm={() => {
          hideAlert()
        }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={alertClasses.button + ' ' + alertClasses.success}
        cancelBtnCssClass={alertClasses.button + ' ' + alertClasses.danger}
        showConfirm={false}
      />
    )
    setTimeout(() => {
      hideAlert()
    }, 1500)
  }

  // Effects
  useEffect(() => {
    axios
      .get('https://api.dt4ever.com/products')
      .then(res =>
        setProducts(
          res.data
            .map(doc => {
              doc.timeLimit = (doc.timeLimit * 24 * 60).toFixed(0)
              return doc
            })
            .sort((a, b) => {
              const textA = a.format
              const textB = b.format
              return textA < textB ? -1 : textA > textB ? 1 : 0
            })
        )
      )
      .catch(e => console.log(e))
    axios
      .get('https://api.dt4ever.com/scripts')
      .then(res =>
        setAds(
          res.data.map(doc => {
            doc.scriptData.forEach(script => {
              script.timeLimit = (script.timeLimit * 24 * 60).toFixed(0)
            })
            return doc
          })
        )
      )
      .catch(e => console.log(e))
    axios
      .get('https://api-preroll.dt4ever.com/scripts')
      .then(res => console.log('Updated preroll-api successfully'))
      .catch(e => console.log('Unable to update preroll api'))
    axios
      .get('https://api-banner.dt4ever.com/scripts')
      .then(res => console.log('Updated banner-api successfully'))
      .catch(e => console.log('Unable to update banner api'))
  }, [])

  useEffect(() => {
    const temp = []
    ads.forEach(ad => {
      ad.scriptData.forEach(script => {
        products.forEach(product => {
          if (
            script.provider === product.provider &&
            script.format === product.format &&
            script.platform === product.platform &&
            ad.distPercentage > 0 &&
            ad.isActive
          ) {
            temp.push({
              name: product.name,
              attachedTo: ad.scriptId
            })
          }
        })
      })
    })
    setDistributed(temp)
  }, [ads, products])

  useEffect(() => {
    if (campaignsUpdate && productToUpdate) {
      productToUpdate.timeLimit = Number(productToUpdate.timeLimit / 24 / 60)
      ads
        .filter(ad => campaignsUpdate?.includes(ad.name))
        .forEach(ad => {
          const newAd = ad
          newAd.scriptData = ad.scriptData.map(script => {
            if (script.name === productToUpdate.name) {
              console.log(productToUpdate)
              console.log(script)
              return {
                ...script,
                affiliate: productToUpdate.affiliate,
                bannerAlt: productToUpdate.bannerAlt,
                bannerImage: productToUpdate.bannerImage,
                bannerType: productToUpdate.bannerType,
                body: productToUpdate.body,
                format: productToUpdate.format,
                name: productToUpdate.name,
                originURL: productToUpdate.originURL,
                platform: productToUpdate.platform,
                popUnderType: productToUpdate.popUnderType,
                provider: productToUpdate.provider,
                timeLimit: productToUpdate.timeLimit,
                trigger: productToUpdate.trigger,
                url: productToUpdate.url,
                zone: productToUpdate.zone
              }
              // return productToUpdate
            } else {
              script.timeLimit = Number(script.timeLimit / 24 / 60)
              return script
            }
          })

          axios
            .put(
              `https://api.dt4ever.com/scripts/${ad.scriptId}/${ad._id}`,
              newAd
            )
            .then(res => {
              console.log(res)
            })
            .catch(e => console.log(e))
        })
      setCampaignsUpdate(null)
      setProductToUpdate(null)
    }
  }, [campaignsUpdate, productToUpdate, ads])

  return (
    <GridContainer>
      <GridItem xs={12}>
        {alert}
        <NewProduct
          open={newProductScreen}
          setOpen={setNewProductScreen}
          updateAlert={updateAlert}
          refreshProducts={refreshProducts}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            color='transparent'
            onClick={e => {
              e.preventDefault()
              setNewProductScreen(true)
            }}
            style={{
              color: '#0d47a1',
              fontSize: '0.8rem',
              marginTop: '1.5rem',
              padding: '0.5rem',
              border: '1.2px solid #0d47a1',
              borderRadius: '8px'
            }}
          >
            New Product
          </Button>
        </div>
      </GridItem>

      <GridItem xs={12}>
        <Card>
          <CardBody>
            <ReactTable
              data={products}
              loading={!(products.length > 0)}
              filterable
              sortable={false}
              columns={[
                {
                  Header: 'Name',
                  accessor: 'name',
                  headerStyle: {
                    fontWeight: 'bold'
                  },
                  style: {
                    color: infoColor[0]
                  },
                  width: 180
                },
                {
                  Header: 'Provider',
                  accessor: 'provider',
                  headerStyle: {
                    fontWeight: 'bold'
                  },
                  width: 120
                },
                {
                  Header: 'Format',
                  accessor: 'format',
                  headerStyle: {
                    fontWeight: 'bold'
                  }
                },
                {
                  Header: 'Platform',
                  accessor: 'platform',
                  headerStyle: {
                    fontWeight: 'bold'
                  }
                },
                {
                  Header: 'Trigger(Clicks)',
                  filterable: false,
                  width: 150,
                  accessor: 'trigger',
                  headerStyle: {
                    fontWeight: 'bold'
                  },
                  style: {
                    textAlign: 'center'
                  }
                },
                {
                  Header: 'Time Limit',
                  filterable: false,
                  accessor: 'timeLimit',
                  headerStyle: {
                    fontWeight: 'bold'
                  },
                  Cell: ({ row }) => {
                    return isNaN(row._original.timeLimit)
                      ? null
                      : row._original.timeLimit
                  }
                },
                {
                  Header: 'Actions',
                  headerStyle: {
                    fontWeight: 'bold',
                    textAlign: 'center'
                  },
                  filterable: false,
                  Cell: ({ row }) => {
                    return (
                      <div>
                        <Button
                          color='info'
                          justIcon
                          size='sm'
                          style={{
                            margin: '0 1rem 0 0'
                          }}
                          onClick={e => {
                            handleClick(e, row._original)
                          }}
                          value='edit'
                          simple
                        >
                          <EditIcon />
                        </Button>

                        <Button
                          color='danger'
                          simple
                          size='sm'
                          justIcon
                          style={{
                            margin: 0
                          }}
                          onClick={e => {
                            const exceptions = distributed.filter(
                              doc => doc.name === row._original.name
                            )
                            const campaignNames = []
                            exceptions.forEach(exception => {
                              ads.forEach(ad => {
                                if (ad.scriptId === exception.attachedTo) {
                                  campaignNames.push(ad.name)
                                }
                              })
                            })
                            if (row._original.format === 'banner') {
                              handleClick(e, row._original)
                            } else {
                              if (exceptions.length === 0) {
                                handleClick(e, row._original)
                              } else {
                                setAlert(
                                  <SweetAlert
                                    danger
                                    style={{
                                      display: 'block',
                                      marginTop: '-100px'
                                    }}
                                    title='Unable to remove Product'
                                    onConfirm={() => {
                                      hideAlert()
                                    }}
                                    onCancel={() => hideAlert()}
                                    confirmBtnCssClass={
                                      alertClasses.button +
                                      ' ' +
                                      alertClasses.success
                                    }
                                    cancelBtnCssClass={
                                      alertClasses.button +
                                      ' ' +
                                      alertClasses.danger
                                    }
                                  >
                                    <Danger>
                                      Please remove product from campaigns:
                                      {campaignNames.map((name, index) => {
                                        const n = campaignNames.length
                                        if (index < n - 2) {
                                          return (
                                            <span key={index}>
                                              <strong>{name}</strong>,
                                            </span>
                                          )
                                        } else if (index === n - 2) {
                                          return (
                                            <span key={index}>
                                              <strong>{name}</strong>
                                            </span>
                                          )
                                        } else {
                                          return (
                                            <span key={index}>
                                              and <strong>{name}</strong>
                                            </span>
                                          )
                                        }
                                      })}
                                    </Danger>
                                  </SweetAlert>
                                )
                              }
                            }
                          }}
                          value='delete'
                        >
                          <DeleteForever />
                        </Button>
                      </div>
                    )
                  },
                  style: {
                    textAlign: 'center'
                  }
                }
              ]}
              defaultPageSize={10}
              className='-striped -highlight'
              showPaginationBottom
              style={{ zIndex: 0 }}
            />
          </CardBody>
        </Card>
      </GridItem>
      {selectedProduct && (
        <EditProduct
          open={editProductScreen}
          setOpen={setEditProductScreen}
          product={selectedProduct}
          updateAlert={updateAlert}
          refreshProducts={refreshProducts}
          ads={ads}
          setCampaignsUpdate={setCampaignsUpdate}
          setProductToUpdate={setProductToUpdate}
        />
      )}
    </GridContainer>
  )
}

export default Products
