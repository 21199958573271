import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

// Firebase
import firebase from '../../firebase/Firebase'

const Logout = () => {
  const history = useHistory()
  useEffect(() => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        history.push('/auth/login')
      })
      .catch(e => console.log(e))
  })
  return <div>Logout</div>
}

export default Logout
