import {
  primaryColor,
  whiteColor,
  hexToRgb,
  primaryBoxShadow,
  successColor,
  blackColor,
  dangerColor,
  roseColor,
  tooltip,
  cardTitle,
  grayColor
} from 'assets/jss/material-dashboard-pro-react.js'

import hoverCardStyle from 'assets/jss/material-dashboard-pro-react/hoverCardStyle.js'

const dashboardStyle = {
  ...hoverCardStyle,
  tooltip,
  cardTitle: {
    ...cardTitle,
    marginTop: '0px',
    marginBottom: '3px'
  },
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px'
  },
  cardProductTitle: {
    ...cardTitle,
    marginTop: '0px',
    marginBottom: '3px',
    textAlign: 'center'
  },
  cardCategory: {
    color: grayColor[0],
    fontSize: '14px',
    paddingTop: '10px',
    marginBottom: '0',
    marginTop: '0',
    margin: '0'
  },
  cardProductDesciprion: {
    textAlign: 'center',
    color: grayColor[0]
  },
  stats: {
    color: grayColor[0],
    fontSize: '12px',
    lineHeight: '22px',
    display: 'inline-flex',
    '& svg': {
      position: 'relative',
      top: '4px',
      width: '16px',
      height: '16px',
      marginRight: '3px'
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      position: 'relative',
      top: '4px',
      fontSize: '16px',
      marginRight: '3px'
    }
  },
  productStats: {
    paddingTop: '7px',
    paddingBottom: '7px',
    margin: '0'
  },
  successText: {
    color: successColor[0]
  },
  upArrowCardCategory: {
    width: 14,
    height: 14
  },
  underChartIcons: {
    width: '17px',
    height: '17px'
  },
  price: {
    color: 'inherit',
    '& h4': {
      marginBottom: '0px',
      marginTop: '0px'
    }
  },
  select: {
    padding: '12px 0 7px',
    fontSize: '.75rem',
    fontWeight: '400',
    lineHeight: '1.42857',
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: grayColor[2],
    letterSpacing: '0',
    '&:focus': {
      backgroundColor: 'transparent'
    },
    '&[aria-owns] + input + svg': {
      transform: 'rotate(180deg)'
    },
    '& + input + svg': {
      transition: 'all 300ms linear'
    }
  },
  selectFormControl: {
    margin: '7px 0 17px 0 !important',
    '& > div': {
      '&:before': {
        borderBottomWidth: '1px !important',
        borderBottomColor: grayColor[4] + '!important'
      },
      '&:after': {
        borderBottomColor: primaryColor[0] + '!important'
      }
    }
  },
  selectLabel: {
    fontSize: '12px',
    textTransform: 'uppercase',
    color: grayColor[2] + ' !important',
    top: '8px'
  },
  selectMenu: {
    '& > div > ul': {
      border: '0',
      padding: '5px 0',
      margin: '0',
      boxShadow: 'none',
      minWidth: '100%',
      borderRadius: '4px',
      boxSizing: 'border-box',
      display: 'block',
      fontSize: '14px',
      textAlign: 'left',
      listStyle: 'none',
      backgroundColor: whiteColor,
      backgroundClip: 'padding-box'
    },
    '& $selectPaper $selectMenuItemSelectedMultiple': {
      backgroundColor: 'inherit'
    },
    '& > div + div': {
      maxHeight: '266px !important'
    }
  },
  selectMenuItem: {
    fontSize: '13px',
    padding: '10px 20px',
    margin: '0 5px',
    borderRadius: '2px',
    transition: 'all 150ms linear',
    display: 'block',
    clear: 'both',
    fontWeight: '400',
    lineHeight: '2',
    whiteSpace: 'nowrap',
    color: grayColor[7],
    paddingRight: '30px',
    '&:hover': {
      backgroundColor: primaryColor[0],
      color: whiteColor,
      ...primaryBoxShadow
    }
  },
  selectMenuItemSelected: {
    backgroundColor: primaryColor[0] + '!important',
    color: whiteColor
  },
  selectMenuItemSelectedMultiple: {
    backgroundColor: 'transparent !important',
    '&:hover': {
      backgroundColor: primaryColor[0] + '!important',
      color: whiteColor,
      ...primaryBoxShadow,
      '&:after': {
        color: whiteColor
      }
    },
    '&:after': {
      top: '16px',
      right: '12px',
      width: '12px',
      height: '5px',
      borderLeft: '2px solid currentColor',
      transform: 'rotate(-45deg)',
      opacity: '1',
      color: grayColor[2],
      position: 'absolute',
      content: "''",
      borderBottom: '2px solid currentColor',
      transition: 'opacity 90ms cubic-bezier(0,0,.2,.1)'
    }
  },
  selectPaper: {
    boxSizing: 'borderBox',
    borderRadius: '4px',
    padding: '0',
    minWidth: '100%',
    display: 'block',
    border: '0',
    boxShadow: '0 2px 5px 0 rgba(' + hexToRgb(blackColor) + ', 0.26)',
    backgroundClip: 'padding-box',
    margin: '2px 0 0',
    fontSize: '14px',
    textAlign: 'left',
    listStyle: 'none',
    backgroundColor: 'transparent',
    maxHeight: '266px'
  },
  datePickerLabel: {
    display: 'block',
    fontSize: '12px',
    textTransform: 'uppercase',
    color: grayColor[2] + ' !important',
    top: '8px',
    margin: '4px 0'
  },
  selectContainer: {
    padding: '24px'
  },
  checkRoot: {
    padding: '14px',
    '&:hover': {
      backgroundColor: 'unset'
    }
  },
  radioRoot: {
    padding: '16px',
    '&:hover': {
      backgroundColor: 'unset'
    }
  },
  labelRoot: {
    marginLeft: '-14px'
  },
  checkboxAndRadio: {
    position: 'relative',
    display: 'block',
    marginTop: '10px',
    marginBottom: '10px'
  },
  checkboxAndRadioHorizontal: {
    position: 'relative',
    display: 'block',
    '&:first-child': {
      marginTop: '10px'
    },
    '&:not(:first-child)': {
      marginTop: '-14px'
    },
    marginTop: '0',
    marginBottom: '0'
  },
  checked: {
    color: primaryColor[0] + '!important'
  },
  checkedIcon: {
    width: '20px',
    height: '20px',
    border: '1px solid rgba(' + hexToRgb(blackColor) + ', .54)',
    borderRadius: '3px'
  },
  uncheckedIcon: {
    width: '0px',
    height: '0px',
    padding: '9px',
    border: '1px solid rgba(' + hexToRgb(blackColor) + ', .54)',
    borderRadius: '3px'
  },
  disabledCheckboxAndRadio: {
    '& $checkedIcon,& $uncheckedIcon,& $radioChecked,& $radioUnchecked': {
      borderColor: blackColor,
      opacity: '0.26',
      color: blackColor
    }
  },
  label: {
    cursor: 'pointer',
    paddingLeft: '0',
    color: grayColor[3],
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: '400',
    display: 'inline-flex',
    transition: '0.3s ease all',
    letterSpacing: 'unset'
  },
  labelHorizontal: {
    color: 'rgba(' + hexToRgb(blackColor) + ', 0.26)',
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: '400',
    paddingTop: '39px',
    marginRight: '0',
    '@media (min-width: 992px)': {
      float: 'right'
    }
  },
  labelHorizontalRadioCheckbox: {
    paddingTop: '22px'
  },
  labelLeftHorizontal: {
    color: 'rgba(' + hexToRgb(blackColor) + ', 0.26)',
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: '14px',
    lineHeight: '1.428571429',
    fontWeight: '400',
    paddingTop: '22px',
    marginRight: '0'
  },
  labelError: {
    color: dangerColor[0]
  },
  radio: {
    color: primaryColor[0] + '!important'
  },
  radioChecked: {
    width: '16px',
    height: '16px',
    border: '1px solid ' + primaryColor[0],
    borderRadius: '50%'
  },
  radioUnchecked: {
    width: '0px',
    height: '0px',
    padding: '7px',
    border: '1px solid rgba(' + hexToRgb(blackColor) + ', .54)',
    borderRadius: '50%'
  },
  inlineChecks: {
    marginTop: '8px'
  },
  iconCheckbox: {
    height: '116px',
    width: '116px',
    color: grayColor[0],
    padding: '0',
    margin: '0 auto 20px',
    '& > span:first-child': {
      borderWidth: '4px',
      borderStyle: 'solid',
      borderColor: grayColor[9],
      textAlign: 'center',
      verticalAlign: 'middle',
      borderRadius: '50%',
      color: 'inherit',
      transition: 'all 0.2s'
    },
    '&:hover': {
      color: roseColor[0],
      '& > span:first-child': {
        borderColor: roseColor[0]
      }
    }
  },
  iconCheckboxChecked: {
    color: roseColor[0],
    '& > span:first-child': {
      borderColor: roseColor[0]
    }
  },
  iconCheckboxIcon: {
    fontSize: '40px',
    lineHeight: '111px'
  },
  switchBase: {
    color: primaryColor[0] + '!important'
  },
  switchIcon: {
    boxShadow: '0 1px 3px 1px rgba(' + hexToRgb(blackColor) + ', 0.4)',
    color: whiteColor + ' !important',
    border: '1px solid rgba(' + hexToRgb(blackColor) + ', .54)'
  },
  switchIconChecked: {
    borderColor: primaryColor[0],
    transform: 'translateX(0px)!important'
  },
  switchBar: {
    width: '30px',
    height: '15px',
    backgroundColor: 'rgb(' + hexToRgb(grayColor[18]) + ')',
    borderRadius: '15px',
    opacity: '0.7!important'
  },
  switchChecked: {
    '& + $switchBar': {
      backgroundColor: 'rgba(' + hexToRgb(primaryColor[0]) + ', 1) !important'
    },
    '& $switchIcon': {
      borderColor: primaryColor[0]
    }
  }
}

export default dashboardStyle
