import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Material UI components
import { makeStyles } from '@material-ui/core/styles'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'

// Core Components
import ImageUpload from 'components/CustomUpload/ImageUpload.js'
import Button from 'components/CustomButtons/Button'
import CustomInput from 'components/CustomInput/CustomInput'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'

// Material UI Icons
import Close from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import SaveIcon from '@material-ui/icons/Save'

// Formik && YUP
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

// React-Select
import ReactSelect from 'react-select'

// styles
import styles from 'assets/jss/material-dashboard-pro-react/modalStyle.js'

// Axios
import axios from 'axios'

// Variables
import {
  primaryColor,
  infoColor
} from '../../assets/jss/material-dashboard-pro-react'

const useStyles = makeStyles(styles)

const ScriptSchema = Yup.object().shape({
  scriptId: Yup.number().required('Required *'),
  source: Yup.string().required('Required *'),
  format: Yup.string().required('Required *')
})

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='down' ref={ref} {...props} />
})

const Campaign = props => {
  // Props destructure
  const {
    campaigns,
    openModal,
    setOpenModal,
    selectedCampaign,
    setSelectedCampaign,
    products,
    sources,
    formats,
    scriptIds,
    refreshCampaigns,
    campaignUpdated
  } = props
  // State

  const [selectedProduct, setSelectedProduct] = useState({})
  const [showProducts, setShowProducts] = useState(true)
  const [loading, setLoading] = useState(false)
  const [trigger, setTrigger] = useState(1)
  const [error, setError] = useState('')

  // Select Options
  const platforms = ['mobile', 'desktop']
  const bannerTypes = ['internal', 'external']
  const zones = [
    'inplayer',
    'footer01',
    'footer02',
    'footer03',
    'home01',
    'home02',
    'listado01',
    'listado02',
    'video01',
    'video02',
    'undervideo'
  ]

  // Styles
  const classes = useStyles()
  const reactSelectStyles = {
    control: styles => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? '#0d47a1' : ''
      }
    }
  }

  // Functions
  const capitalizeFirstLetter = str => {
    const capArray = str.split('')
    capArray[0] = capArray[0]?.toUpperCase()
    return capArray.join('')
  }
  const handleClose = () => {
    setOpenModal(false)
  }
  const handleSubmit = (values, callback) => {
    if (selectedCampaign) {
      const modifiedCampaign = {
        scriptId: selectedCampaign.scriptId,
        name: values.name,
        resetTime: values.resetTime,
        source: values.source,
        isActive: values.isActive,
        scriptData: values.adData
      }
      console.log('CAMPAIGN', modifiedCampaign)
      modifiedCampaign.scriptData.forEach(
        product => (product.timeLimit = product.timeLimit / 24 / 60)
      )

      axios
        .put(
          `https://api.dt4ever.com/scripts/${
            selectedCampaign.scriptId.length === 3
              ? selectedCampaign.scriptId.slice(0, 2)
              : selectedCampaign.scriptId.slice(0, 3)
          }/${selectedCampaign._id}`,
          modifiedCampaign
        )
        .then(res => {
          console.log(res)
          refreshCampaigns()
          campaignUpdated()
          callback()
          handleClose()
        })
        .catch(e => console.log(e))
    } else {
      const newCampaign = {
        scriptId: Math.max(...scriptIds.map(script => script.scriptId)) + 1,
        name: values.name,
        resetTime: values.resetTime,
        source: values.source,
        isActive: values.isActive,
        scriptData: values.adData
      }
      newCampaign.scriptData.forEach(product => {
        product.timeLimit = product.timeLimit / 24 / 60
        return product
      })
      axios
        .post(
          `https://api.dt4ever.com/scripts/${newCampaign.scriptId}`,
          newCampaign
        )
        .then(res => {
          refreshCampaigns()
          campaignUpdated()
          callback()
          handleClose()
        })
        .catch(e => console.log(e))
    }
  }
  // LifeCycle
  useEffect(() => {
    selectedCampaign?.scriptData
      ? setSelectedProduct(selectedCampaign.scriptData[0])
      : setSelectedProduct({})
    selectedCampaign ? setShowProducts(false) : setShowProducts(true)
  }, [selectedCampaign])

  useEffect(() => {
    if (selectedCampaign) {
      setTrigger(
        Math.min(...selectedCampaign.scriptData.map(product => product.trigger))
      )
    }
  }, [selectedCampaign])
  return (
    <div>
      <Formik
        initialValues={{
          // Campaign
          scriptId: selectedCampaign ? selectedCampaign.scriptId : '',
          name: selectedCampaign ? selectedCampaign.name : '',
          source: selectedCampaign ? selectedCampaign.source : '',
          isActive: selectedCampaign ? selectedCampaign.isActive : false,
          adData: selectedCampaign ? selectedCampaign.scriptData : [],
          resetTime: selectedCampaign ? selectedCampaign.resetTime : '',
          // Product
          productIndex: 0,
          productName: selectedProduct?.name ? selectedProduct?.name : '',
          distPercentage: selectedProduct?.distPercentage
            ? selectedProduct?.distPercentage
            : 0,
          format: selectedProduct?.format ? selectedProduct.format : '',
          platform: selectedProduct?.platform ? selectedProduct.platform : '',
          provider: selectedProduct?.provider ? selectedProduct.provider : '',
          zone: selectedProduct?.zone ? selectedProduct.zone : '',
          bannerType: selectedProduct?.bannerType
            ? selectedProduct?.bannerType
            : '',
          bannerImage: selectedProduct?.bannerImage
            ? selectedProduct?.bannerImage
            : '',
          bannerAlt: selectedProduct?.bannerAlt
            ? selectedProduct?.bannerAlt
            : '',
          trigger: selectedProduct?.trigger ? selectedProduct?.trigger : 0,
          affiliate: selectedProduct?.affiliate
            ? selectedProduct?.affiliate
            : '',
          url: selectedProduct?.url ? selectedProduct?.url : '',
          originURL: selectedProduct?.originURL
            ? selectedProduct?.originURL
            : '',
          body: selectedProduct?.body ? selectedProduct?.body : '',
          timeLimit: selectedProduct?.timeLimit
            ? selectedProduct?.timeLimit
            : 0,
          popUnderType: selectedProduct?.popUnderType
            ? selectedProduct?.popUnderType
            : ''
        }}
        onSubmit={values => {
          console.log(values)
        }}
        enableReinitialize
        validationSchema={ScriptSchema}
      >
        {({ values, errors, touched, setFieldValue, resetForm }) => (
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal
            }}
            open={openModal}
            transition={Transition}
            keepMounted
            onClose={e => {
              setTrigger(1)
              setSelectedProduct({})
              setSelectedCampaign(null)
              resetForm()
              handleClose()
            }}
            aria-labelledby='modal-slide-title'
            aria-describedby='modal-slide-description'
          >
            <DialogTitle
              id='classic-modal-slide-title'
              disableTypography
              className={classes.modalHeader}
              style={{
                backgroundColor: primaryColor[0],
                fontWeight: 'bold'
              }}
            >
              {!loading ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start'
                  }}
                >
                  <span style={{ color: infoColor[0], marginLeft: '1rem' }}>
                    <strong>NAME</strong>
                  </span>
                  <span style={{ color: '#fff', marginLeft: '0.3rem' }}>
                    {values.name}
                  </span>
                  <span style={{ color: infoColor[0], marginLeft: '1rem' }}>
                    <strong>SOURCE</strong>
                  </span>
                  {!selectedCampaign ? (
                    <span style={{ color: '#fff', marginLeft: '0.3rem' }}>
                      {capitalizeFirstLetter(values.source)}
                    </span>
                  ) : (
                    <div
                      style={{
                        width: '130px',
                        marginTop: '-0.5rem',
                        marginLeft: '1rem'
                      }}
                    >
                      <ReactSelect
                        name='source'
                        placeholder='Source'
                        value={{
                          value: values.source,
                          label: capitalizeFirstLetter(values.source)
                        }}
                        styles={reactSelectStyles}
                        options={sources.map(source => {
                          const allCampaigns = JSON.parse(
                            JSON.stringify(campaigns)
                          )
                          const possibleCampaigns = allCampaigns.filter(
                            campaign =>
                              campaign.isActive &&
                              campaign.source === source &&
                              campaign.scriptData[0]?.format === values.format
                          )
                          let disabled
                          if (possibleCampaigns.length === 0) {
                            disabled = true
                          } else {
                            disabled = false
                          }
                          return {
                            value: source,
                            label: capitalizeFirstLetter(source),
                            disabled: disabled
                          }
                        })}
                        onChange={e => {
                          setLoading(true)
                          const allCampaigns = JSON.parse(
                            JSON.stringify(campaigns)
                          )
                          const possibleCampaigns = allCampaigns.filter(
                            campaign =>
                              campaign.isActive &&
                              campaign.source === e.value &&
                              campaign.scriptData[0].format === values.format
                          )
                          possibleCampaigns[0].scriptData.forEach(
                            script =>
                              (script.timeLimit = (
                                script.timeLimit *
                                24 *
                                60
                              ).toFixed(0))
                          )

                          setSelectedCampaign(possibleCampaigns[0])
                          setTimeout(() => {
                            setLoading(false)
                          }, 1000)
                        }}
                        isOptionDisabled={option => {
                          return option.disabled
                        }}
                      />
                    </div>
                  )}

                  <span style={{ color: infoColor[0], marginLeft: '1rem' }}>
                    <strong>FORMAT</strong>
                  </span>
                  {!selectedCampaign ? (
                    <span style={{ color: '#fff', marginLeft: '0.3rem' }}>
                      {capitalizeFirstLetter(values.format)}
                    </span>
                  ) : (
                    <div
                      style={{
                        width: '130px',
                        marginTop: '-0.5rem',
                        marginLeft: '1rem'
                      }}
                    >
                      <ReactSelect
                        name='format'
                        placeholder='Format'
                        value={{
                          value: values.format,
                          label: capitalizeFirstLetter(values.format)
                        }}
                        styles={reactSelectStyles}
                        options={formats.map(format => {
                          const allCampaigns = JSON.parse(
                            JSON.stringify(campaigns)
                          )
                          const possibleCampaigns = allCampaigns.filter(
                            campaign =>
                              campaign.isActive &&
                              campaign.source === values.source &&
                              campaign.scriptData[0].format === format
                          )
                          let disabled
                          if (possibleCampaigns.length === 0) {
                            disabled = true
                          } else {
                            disabled = false
                          }
                          return {
                            value: format,
                            label: capitalizeFirstLetter(format),
                            disabled: disabled
                          }
                        })}
                        onChange={e => {
                          setLoading(true)
                          const allCampaigns = JSON.parse(
                            JSON.stringify(campaigns)
                          )
                          const possibleCampaigns = allCampaigns.filter(
                            campaign =>
                              campaign.isActive &&
                              campaign.source === values.source &&
                              campaign.scriptData[0].format === e.value
                          )
                          possibleCampaigns[0].scriptData.forEach(
                            script =>
                              (script.timeLimit = (
                                script.timeLimit *
                                24 *
                                60
                              ).toFixed(0))
                          )
                          setSelectedCampaign(possibleCampaigns[0])
                          setTimeout(() => {
                            setLoading(false)
                          }, 1000)
                        }}
                        isOptionDisabled={option => {
                          return option.disabled
                        }}
                      />
                    </div>
                  )}
                  <span style={{ color: infoColor[0], marginLeft: '1rem' }}>
                    <strong>PLATFORM</strong>
                  </span>
                  {!selectedCampaign ? (
                    <span style={{ color: '#fff', marginLeft: '0.3rem' }}>
                      {capitalizeFirstLetter(values.platform)}
                    </span>
                  ) : (
                    <div
                      style={{
                        width: '130px',
                        marginTop: '-0.5rem',
                        marginLeft: '1rem'
                      }}
                    >
                      <ReactSelect
                        name='platform'
                        placeholder='Platform'
                        value={{
                          value: values.platform,
                          label: capitalizeFirstLetter(values.platform)
                        }}
                        styles={reactSelectStyles}
                        options={platforms.map(platform => {
                          return {
                            value: platform,
                            label: capitalizeFirstLetter(platform)
                          }
                        })}
                        onChange={e => {
                          const persistedData = {
                            format: values.format,
                            zone: values.zone
                          }

                          setFieldValue('format', persistedData.format)
                          setFieldValue('zone', persistedData.zone)
                          setFieldValue('platform', e.value)

                          setFieldValue('productIndex', -1)
                        }}
                      />
                    </div>
                  )}

                  {values.format === 'banner' && selectedCampaign ? (
                    <span style={{ color: infoColor[0], marginLeft: '1rem' }}>
                      <strong>ZONE</strong>
                    </span>
                  ) : null}

                  {values.format === 'preRoll' && selectedCampaign ? (
                    <>
                      <span style={{ color: infoColor[0], marginLeft: '1rem' }}>
                        <strong>RESET</strong>
                      </span>
                      <div>
                        <input
                          style={{
                            borderRadius: '10%',
                            padding: '9px',
                            border: '1px solid #D4D4D4',
                            marginLeft: '1rem',
                            marginTop: '-0.5rem',
                            fontSize: '1rem',
                            fontWeight: 'bold',
                            width: '4rem'
                          }}
                          type='number'
                          value={values.resetTime}
                          onChange={e => {
                            setFieldValue('resetTime', e.target.value)
                          }}
                        />
                      </div>
                    </>
                  ) : null}

                  <div
                    style={{
                      width: '130px',
                      marginTop: '-0.5rem',
                      marginLeft: '1rem'
                    }}
                  >
                    {values.format === 'banner' && selectedCampaign ? (
                      <ReactSelect
                        name='zone'
                        placeholder='Zone'
                        value={{
                          value: values.zone,
                          label: capitalizeFirstLetter(values.zone)
                        }}
                        styles={reactSelectStyles}
                        options={zones.map(zone => {
                          return {
                            value: zone,
                            label: capitalizeFirstLetter(zone)
                          }
                        })}
                        onChange={e => {
                          console.log('happening')
                          const persistedData = {
                            format: values.format,
                            platform: values.platform
                          }

                          setFieldValue('format', persistedData.format)
                          setFieldValue('platform', persistedData.platform)
                          setFieldValue('zone', e.value)

                          setFieldValue('productIndex', -1)
                        }}
                      />
                    ) : null}
                  </div>

                  <Button
                    justIcon
                    className={classes.modalCloseButton}
                    style={{ marginLeft: 'auto' }}
                    key='close'
                    aria-label='Close'
                    color='transparent'
                    onClick={e => {
                      setTrigger(1)
                      setSelectedProduct({})
                      setSelectedCampaign(null)
                      resetForm()
                      handleClose()
                    }}
                  >
                    <Close
                      className={classes.modalClose}
                      style={{ color: '#fff' }}
                    />
                  </Button>
                </div>
              ) : (
                <h4 style={{ color: '#fff', marginTop: 0 }}> Loading ....</h4>
              )}
            </DialogTitle>
            <DialogContent
              id='modal-slide-description'
              className={classes.modalBody}
            >
              {!loading ? (
                <>
                  <GridContainer>
                    {!selectedCampaign ? (
                      <GridItem
                        xs={
                          values.format === 'banner' ||
                          values.format === 'preRoll'
                            ? 6
                            : 4
                        }
                      >
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor='source'
                            className={classes.selectLabel}
                          >
                            Source
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={values.source}
                            onChange={e => {
                              setFieldValue('source', e.target.value)
                              if (values.format) {
                                setFieldValue(
                                  'name',
                                  capitalizeFirstLetter(e.target.value) +
                                    '-' +
                                    capitalizeFirstLetter(values.format)
                                )
                              }
                            }}
                            inputProps={{
                              name: 'source',
                              id: 'sourceSelect',
                              disabled: !!selectedCampaign
                            }}
                          >
                            {sources.map((doc, index) => {
                              if (doc === values.source) {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={doc}
                                    label={doc}
                                    selected
                                    classes={{
                                      root: classes.selectMenuItem
                                    }}
                                  >
                                    {doc}
                                  </MenuItem>
                                )
                              } else {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={doc}
                                    label={doc}
                                    classes={{
                                      root: classes.selectMenuItem
                                    }}
                                  >
                                    {doc}
                                  </MenuItem>
                                )
                              }
                            })}
                          </Select>
                          {errors.source && touched.source ? (
                            <div className={classes.errors}>
                              {errors.source}
                            </div>
                          ) : null}
                        </FormControl>
                      </GridItem>
                    ) : null}
                    {!selectedCampaign ? (
                      <GridItem
                        xs={
                          values.format === 'banner' ||
                          values.format === 'preRoll'
                            ? 6
                            : 4
                        }
                      >
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor='format'
                            className={classes.selectLabel}
                          >
                            Format
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={values.format}
                            onChange={e => {
                              setFieldValue('format', e.target.value)
                              if (values.source) {
                                setFieldValue(
                                  'name',
                                  capitalizeFirstLetter(values.source) +
                                    '-' +
                                    capitalizeFirstLetter(e.target.value)
                                )
                              }
                            }}
                            inputProps={{
                              name: 'format',
                              id: 'formatSelect'
                            }}
                            disabled={values.adData.length > 0}
                          >
                            {formats.map((doc, index) => {
                              if (doc === values.format) {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={doc}
                                    classes={{
                                      root: classes.selectMenuItem
                                    }}
                                    defaultValue
                                  >
                                    {doc}
                                  </MenuItem>
                                )
                              } else {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={doc}
                                    classes={{
                                      root: classes.selectMenuItem
                                    }}
                                  >
                                    {doc}
                                  </MenuItem>
                                )
                              }
                            })}
                          </Select>
                          {errors.format && touched.format ? (
                            <div className={classes.errors}>
                              {errors.format}
                            </div>
                          ) : null}
                        </FormControl>
                      </GridItem>
                    ) : null}
                    {!selectedCampaign ? (
                      <GridItem
                        xs={
                          values.format === 'banner' ||
                          values.format === 'preRoll'
                            ? 6
                            : 4
                        }
                      >
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor='platform'
                            className={classes.selectLabel}
                          >
                            Platform
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={values.platform}
                            onChange={e => {
                              if (selectedCampaign) {
                                const persistedData = {
                                  format: values.format
                                }

                                setSelectedProduct({
                                  format: persistedData.format,
                                  platform: e.target.value
                                })

                                setFieldValue('productIndex', -1)
                              } else {
                                setFieldValue('platform', e.target.value)
                                setFieldValue('productIndex', -1)
                              }
                            }}
                            inputProps={{
                              name: 'platform',
                              id: 'platformSelect'
                            }}
                          >
                            {platforms.map((platform, platformIndex) => {
                              if (platform === values.platform) {
                                return (
                                  <MenuItem
                                    key={platformIndex}
                                    value={platform}
                                    classes={{
                                      root: classes.selectMenuItem
                                    }}
                                    defaultValue
                                    disabled={values.platform === platform}
                                  >
                                    {platform}
                                  </MenuItem>
                                )
                              } else {
                                return (
                                  <MenuItem
                                    key={platformIndex}
                                    value={platform}
                                    classes={{
                                      root: classes.selectMenuItem
                                    }}
                                  >
                                    {platform}
                                  </MenuItem>
                                )
                              }
                            })}
                          </Select>
                        </FormControl>
                      </GridItem>
                    ) : null}
                    {!selectedCampaign && values.format === 'banner' ? (
                      <GridItem xs={6}>
                        <FormControl
                          fullWidth
                          className={classes.selectFormControl}
                        >
                          <InputLabel
                            htmlFor='zone'
                            className={classes.selectLabel}
                          >
                            Zone
                          </InputLabel>
                          <Select
                            MenuProps={{
                              className: classes.selectMenu
                            }}
                            classes={{
                              select: classes.select
                            }}
                            value={values.zone}
                            onChange={e => {
                              if (selectedCampaign) {
                                const persistedData = {
                                  format: values.format,
                                  platform: values.platform
                                }

                                setSelectedProduct({
                                  format: persistedData.format,
                                  platform: persistedData.platform,
                                  zone: e.target.value
                                })

                                setFieldValue('productIndex', -1)
                              } else {
                                setFieldValue('zone', e.target.value)
                                setFieldValue('productIndex', -1)
                              }
                            }}
                            inputProps={{
                              name: 'zone',
                              id: 'zoneSelect'
                            }}
                          >
                            {zones.map((doc, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={doc}
                                  classes={{
                                    root: classes.selectMenuItem
                                  }}
                                  disabled={values.zone === doc}
                                >
                                  {doc}
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                      </GridItem>
                    ) : null}
                    {!selectedCampaign && values.format === 'preRoll' ? (
                      <GridItem xs={6}>
                        <CustomInput
                          labelText='Reset Timer(Hours)'
                          id='resetTime'
                          formControlProps={{
                            fullWidth: true
                          }}
                          inputProps={{
                            name: 'resetTime',
                            type: 'number',
                            value: values.resetTime,
                            onChange: e => {
                              setFieldValue('resetTime', e.target.value)
                            }
                          }}
                        />
                      </GridItem>
                    ) : null}
                  </GridContainer>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end'
                    }}
                  >
                    {values.format === 'interstitial' ||
                    values.format === 'popUnder' ? (
                      <div style={{ marginRight: 'auto' }}>
                        Trigger(clicks):{' '}
                        <Button
                          simple
                          color='primary'
                          style={{ padding: '8px', margin: 0 }}
                          onClick={e => {
                            if (trigger <= 1) {
                              return null
                            } else {
                              setTrigger(trigger - 1)
                              const persistedData = {
                                format: values.format,
                                platform: values.platform
                              }

                              e.preventDefault()
                              setFieldValue('productIndex', -1)
                              setFieldValue('productName', '')
                              setFieldValue('distPercentage', 0)
                              setFieldValue('format', persistedData.format)
                              setFieldValue('platform', persistedData.platform)
                              setFieldValue('provider', '')
                              setFieldValue('zone', '')
                              setFieldValue('bannerType', '')
                              setFieldValue('bannerImage', '')
                              setFieldValue('bannerAlt', '')
                              setFieldValue('trigger', 0)
                              setFieldValue('affiliate', '')
                              setFieldValue('url', '')
                              setFieldValue('originURL', '')
                              setFieldValue('body', '')
                              setFieldValue('timeLimit', 0)
                              setFieldValue('popUnderType', '')
                            }
                          }}
                        >
                          &lt;
                        </Button>
                        {trigger}
                        <Button
                          simple
                          color='primary'
                          style={{ padding: '8px', margin: 0 }}
                          onClick={e => {
                            setTrigger(trigger + 1)
                            const persistedData = {
                              format: values.format,
                              platform: values.platform
                            }

                            e.preventDefault()
                            setFieldValue('productIndex', -1)
                            setFieldValue('productName', '')
                            setFieldValue('distPercentage', 0)
                            setFieldValue('format', persistedData.format)
                            setFieldValue('platform', persistedData.platform)
                            setFieldValue('provider', '')
                            setFieldValue('zone', '')
                            setFieldValue('bannerType', '')
                            setFieldValue('bannerImage', '')
                            setFieldValue('bannerAlt', '')
                            setFieldValue('trigger', 0)
                            setFieldValue('affiliate', '')
                            setFieldValue('url', '')
                            setFieldValue('originURL', '')
                            setFieldValue('body', '')
                            setFieldValue('timeLimit', 0)
                            setFieldValue('popUnderType', '')
                          }}
                        >
                          &gt;
                        </Button>
                      </div>
                    ) : null}

                    {values.adData.length > 1 ? (
                      <Button
                        color='danger'
                        style={{ padding: '8px', margin: '0 0 0 0' }}
                        onClick={e => {
                          e.preventDefault()
                          const allProducts = JSON.parse(
                            JSON.stringify(values.adData)
                          )
                          const filteredProducts = allProducts.filter(
                            (product, index) => index !== values.productIndex
                          )
                          setFieldValue('adData', filteredProducts)
                          setFieldValue('productIndex', 0)
                          setFieldValue('productName', filteredProducts[0].name)
                          setFieldValue(
                            'distPercentage',
                            filteredProducts[0].distPercentage
                              ? filteredProducts[0].distPercentage
                              : 0
                          )
                          setFieldValue('format', filteredProducts[0].format)
                          setFieldValue(
                            'platform',
                            filteredProducts[0].platform
                          )
                          setFieldValue(
                            'provider',
                            filteredProducts[0].provider
                          )
                          setFieldValue('zone', filteredProducts[0].zone)
                          setFieldValue(
                            'bannerType',
                            filteredProducts[0].bannerType
                          )
                          setFieldValue(
                            'bannerImage',
                            filteredProducts[0].bannerImage
                          )
                          setFieldValue(
                            'bannerAlt',
                            filteredProducts[0].bannerAlt
                          )
                          setFieldValue('trigger', filteredProducts[0].trigger)
                          setFieldValue(
                            'affiliate',
                            filteredProducts[0].affiliate
                          )
                          setFieldValue('url', filteredProducts[0].url)
                          setFieldValue(
                            'originURL',
                            filteredProducts[0].originURL
                          )
                          setFieldValue('body', filteredProducts[0].body)
                          setFieldValue(
                            'timeLimit',
                            filteredProducts[0].timeLimit
                          )
                          setFieldValue(
                            'popUnderType',
                            filteredProducts[0].popUnderType
                          )
                        }}
                        value='remove'
                        simple
                      >
                        Remove product <RemoveIcon />
                      </Button>
                    ) : null}
                  </div>
                  <Form>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4} lg={3}>
                        {values.adData.length > 0 && !showProducts ? (
                          <Card
                            style={{ backgroundColor: '#F7F7F7', zIndex: 0 }}
                          >
                            <CardHeader>
                              <h5
                                style={{ textAlign: 'center', marginBottom: 0 }}
                              >
                                {' '}
                                Assigned Products{' '}
                              </h5>
                            </CardHeader>
                            <CardBody>
                              {values.adData
                                .filter((product, originalIndex) => {
                                  if (
                                    values.format === 'interstitial' ||
                                    values.format === 'popUnder'
                                  ) {
                                    if (
                                      values.platform === product.platform &&
                                      trigger === product.trigger
                                    ) {
                                      product.originalIndex = originalIndex
                                      return product
                                    } else {
                                      return null
                                    }
                                  } else if (values.format === 'preRoll') {
                                    if (values.platform === product.platform) {
                                      product.originalIndex = originalIndex
                                      return product
                                    } else {
                                      return null
                                    }
                                  } else if (values.format === 'banner') {
                                    if (
                                      values.platform === product.platform &&
                                      values.zone === product.zone
                                    ) {
                                      product.originalIndex = originalIndex
                                      return product
                                    } else {
                                      return null
                                    }
                                  } else {
                                    return null
                                  }
                                })
                                .map((product, productIndex) => {
                                  return (
                                    <Button
                                      fullWidth
                                      key={productIndex}
                                      color={
                                        values.adData[values.productIndex]
                                          ?.name === product.name &&
                                        values.adData[values.productIndex]
                                          ?.trigger === product.trigger
                                          ? 'primary'
                                          : null
                                      }
                                      style={{
                                        padding: '8px',
                                        margin: '8px 8px 8px 8px',
                                        borderRadius: '5px',
                                        fontWeight: 'bold'
                                      }}
                                      onClick={e => {
                                        e.preventDefault()
                                        setFieldValue(
                                          'productIndex',
                                          product.originalIndex
                                        )
                                        setFieldValue(
                                          'productName',
                                          product.name
                                        )
                                        setFieldValue(
                                          'distPercentage',
                                          product.distPercentage
                                            ? product.distPercentage
                                            : 0
                                        )
                                        setFieldValue('format', product.format)
                                        setFieldValue(
                                          'platform',
                                          product.platform
                                        )
                                        setFieldValue(
                                          'provider',
                                          product.provider
                                        )
                                        setFieldValue('zone', product.zone)
                                        setFieldValue(
                                          'bannerType',
                                          product.bannerType
                                        )
                                        setFieldValue(
                                          'bannerImage',
                                          product.bannerImage
                                        )
                                        setFieldValue(
                                          'bannerAlt',
                                          product.bannerAlt
                                        )
                                        setFieldValue(
                                          'trigger',
                                          product.trigger
                                        )
                                        setFieldValue(
                                          'affiliate',
                                          product.affiliate
                                        )
                                        setFieldValue('url', product.url)
                                        setFieldValue(
                                          'originURL',
                                          product.originURL
                                        )
                                        setFieldValue('body', product.body)
                                        setFieldValue(
                                          'timeLimit',
                                          product.timeLimit
                                        )
                                        setFieldValue(
                                          'popUnderType',
                                          product.popUnderType
                                        )
                                      }}
                                    >
                                      <GridContainer>
                                        <GridItem
                                          xs={8}
                                          style={{ textAlign: 'left' }}
                                        >
                                          {product?.name?.length <= 25
                                            ? product?.name
                                            : product?.name?.slice(0, 24) +
                                              '...'}
                                        </GridItem>
                                        <GridItem
                                          xs={3}
                                          style={{
                                            textAlign: 'left',
                                            marginLeft: '1rem'
                                          }}
                                        >
                                          {product.distPercentage &&
                                            values.format !== 'preRoll' &&
                                            (product.distPercentage + '%' ||
                                              '0 %')}
                                          {values.format === 'preRoll' &&
                                            (product.trigger < 7
                                              ? product.trigger
                                              : 'backfill')}
                                        </GridItem>
                                      </GridContainer>
                                    </Button>
                                  )
                                })}
                              {!showProducts ? (
                                <Button
                                  simple
                                  fullWidth
                                  style={{
                                    zIndex: '10',
                                    color: '#0D47A1',
                                    fontSize: '0.75rem',
                                    padding: '8px',
                                    margin: '0 0 0.25rem 0'
                                  }}
                                  onClick={e => {
                                    setShowProducts(true)
                                  }}
                                >
                                  <AddIcon /> <span>Add product</span>
                                </Button>
                              ) : null}
                            </CardBody>
                          </Card>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={8} lg={9}>
                        {values.adData.length > 0 && !showProducts ? (
                          <Card style={{ backgroundColor: '#F7F7F7' }}>
                            <CardBody>
                              {values.adData[values.productIndex]?.name ===
                              values.productName ? (
                                <div style={{ width: '100%' }}>
                                  <GridContainer>
                                    <GridItem
                                      xs={12}
                                      sm={12}
                                      md={values.format !== 'preRoll' ? 8 : 12}
                                      lg={values.format !== 'preRoll' ? 8 : 12}
                                    >
                                      <CustomInput
                                        labelText='Name'
                                        id='name'
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          name: 'name',
                                          value: values.productName,
                                          onChange: e => {
                                            setError('')
                                            values.adData[
                                              values.productIndex
                                            ].name = e.target.value
                                            setFieldValue(
                                              'productName',
                                              e.target.value
                                            )
                                          }
                                        }}
                                      />

                                      {errors.name && touched.name ? (
                                        <div className={classes.errors}>
                                          {errors.name}
                                        </div>
                                      ) : null}
                                    </GridItem>
                                    {values.format !== 'preRoll' && (
                                      <GridItem xs={12} sm={12} md={4} lg={4}>
                                        <InputLabel
                                          htmlFor='distribution'
                                          className={classes.selectLabel}
                                          style={{
                                            marginTop: '16px'
                                          }}
                                        >
                                          Distribution(%){' '}
                                          {values.distPercentage}
                                        </InputLabel>
                                        <input
                                          type='range'
                                          id='distribution'
                                          name='distribution'
                                          min='0'
                                          max='100'
                                          value={values.distPercentage}
                                          step='5'
                                          onChange={e => {
                                            if (values.productIndex === -1) {
                                              return null
                                            }
                                            setError('')
                                            let distributedScripts
                                            if (
                                              values.format === 'popUnder' ||
                                              values.format === 'interstitial'
                                            ) {
                                              distributedScripts = values.adData.filter(
                                                script =>
                                                  script.format ===
                                                    values.format &&
                                                  script.platform ===
                                                    values.platform &&
                                                  script.trigger ===
                                                    values.trigger &&
                                                  script.distPercentage > 0 &&
                                                  script._id !==
                                                    values.adData[
                                                      values.productIndex
                                                    ]._id
                                              )
                                            } else if (
                                              values.format === 'preRoll'
                                            ) {
                                              distributedScripts = values.adData.filter(
                                                script =>
                                                  script.format ===
                                                    values.format &&
                                                  script.platform ===
                                                    values.platform &&
                                                  script.distPercentage > 0 &&
                                                  script._id !==
                                                    values.adData[
                                                      values.productIndex
                                                    ]._id
                                              )
                                            } else if (
                                              values.format === 'banner'
                                            ) {
                                              distributedScripts = values.adData.filter(
                                                script =>
                                                  script.format ===
                                                    values.format &&
                                                  script.platform ===
                                                    values.platform &&
                                                  script.zone === values.zone &&
                                                  script.distPercentage > 0 &&
                                                  script._id !==
                                                    values.adData[
                                                      values.productIndex
                                                    ]._id
                                              )
                                            }

                                            if (
                                              distributedScripts.length >= 1
                                            ) {
                                              const distributionTotal = distributedScripts.reduce(
                                                (sum, el) =>
                                                  (sum += Number(
                                                    el.distPercentage
                                                  )
                                                    ? Number(el.distPercentage)
                                                    : 0),
                                                0
                                              )
                                              if (
                                                distributionTotal +
                                                  Number(e.target.value) <=
                                                100
                                              ) {
                                                values.adData[
                                                  values.productIndex
                                                ].distPercentage = Number(
                                                  e.target.value
                                                )
                                                setFieldValue(
                                                  'distPercentage',
                                                  Number(e.target.value)
                                                )
                                              } else if (
                                                distributionTotal +
                                                  Number(e.target.value) >
                                                100
                                              ) {
                                                values.adData[
                                                  values.productIndex
                                                ].distPercentage = Number(
                                                  100 -
                                                    Number(distributionTotal)
                                                )
                                                setFieldValue(
                                                  'distPercentage',
                                                  100 -
                                                    Number(distributionTotal)
                                                )
                                              }
                                            } else {
                                              values.adData[
                                                values.productIndex
                                              ].distPercentage = Number(
                                                e.target.value
                                              )
                                              setFieldValue(
                                                'distPercentage',
                                                Number(e.target.value)
                                              )
                                            }
                                          }}
                                          style={{
                                            width: '100%',
                                            margin: '12px 0px'
                                          }}
                                        />

                                        {errors.distribution &&
                                        touched.distribution ? (
                                          <div className={classes.errors}>
                                            {errors.distribution}
                                          </div>
                                        ) : null}
                                      </GridItem>
                                    )}

                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                      <CustomInput
                                        labelText='Provider'
                                        id='provider'
                                        formControlProps={{
                                          fullWidth: true
                                        }}
                                        inputProps={{
                                          name: 'provider',
                                          value: values.provider,
                                          onChange: e => {
                                            values.adData[
                                              values.productIndex
                                            ].provider = e.target.value
                                            setFieldValue(
                                              'provider',
                                              e.target.value
                                            )
                                          }
                                        }}
                                      />

                                      {errors.provider && touched.provider ? (
                                        <div className={classes.errors}>
                                          {errors.provider}
                                        </div>
                                      ) : null}
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                      <FormControl
                                        fullWidth
                                        className={classes.selectFormControl}
                                      >
                                        <InputLabel
                                          htmlFor='format'
                                          className={classes.selectLabel}
                                        >
                                          Format
                                        </InputLabel>
                                        <Select
                                          MenuProps={{
                                            className: classes.selectMenu
                                          }}
                                          classes={{
                                            select: classes.select
                                          }}
                                          value={values.format}
                                          onChange={e => {
                                            values.adData[
                                              values.productIndex
                                            ].format = e.target.value
                                            setFieldValue(
                                              'format',
                                              e.target.value
                                            )
                                          }}
                                          inputProps={{
                                            name: 'format',
                                            id: 'formatSelect'
                                          }}
                                        >
                                          {formats.map(
                                            (formatDoc, formatIndex) => {
                                              if (formatDoc === values.format) {
                                                return (
                                                  <MenuItem
                                                    key={formatIndex}
                                                    value={formatDoc}
                                                    classes={{
                                                      root:
                                                        classes.selectMenuItem
                                                    }}
                                                    defaultValue
                                                  >
                                                    {formatDoc}
                                                  </MenuItem>
                                                )
                                              } else {
                                                return (
                                                  <MenuItem
                                                    key={formatIndex}
                                                    value={formatDoc}
                                                    classes={{
                                                      root:
                                                        classes.selectMenuItem
                                                    }}
                                                    disabled={
                                                      formatDoc !==
                                                      selectedCampaign
                                                        ?.scriptData[0].format
                                                    }
                                                  >
                                                    {formatDoc}
                                                  </MenuItem>
                                                )
                                              }
                                            }
                                          )}
                                        </Select>
                                        {errors.format && touched.format ? (
                                          <div className={classes.errors}>
                                            {errors.format}
                                          </div>
                                        ) : null}
                                      </FormControl>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={6} lg={6}>
                                      <FormControl
                                        fullWidth
                                        className={classes.selectFormControl}
                                      >
                                        <InputLabel
                                          htmlFor='platform'
                                          className={classes.selectLabel}
                                        >
                                          Platform
                                        </InputLabel>
                                        <Select
                                          MenuProps={{
                                            className: classes.selectMenu
                                          }}
                                          classes={{
                                            select: classes.select
                                          }}
                                          value={values.platform}
                                          onChange={e => {
                                            values.adData[
                                              values.productIndex
                                            ].platform = e.target.value
                                            setFieldValue(
                                              'platform',
                                              e.target.value
                                            )
                                          }}
                                          inputProps={{
                                            name: 'platform',
                                            id: 'platformSelect'
                                          }}
                                        >
                                          {platforms.map(
                                            (platform, platformIndex) => {
                                              if (
                                                platform === values.platform
                                              ) {
                                                return (
                                                  <MenuItem
                                                    key={platformIndex}
                                                    value={platform}
                                                    classes={{
                                                      root:
                                                        classes.selectMenuItem
                                                    }}
                                                    defaultValue
                                                  >
                                                    {platform}
                                                  </MenuItem>
                                                )
                                              } else {
                                                return (
                                                  <MenuItem
                                                    key={platformIndex}
                                                    value={platform}
                                                    classes={{
                                                      root:
                                                        classes.selectMenuItem
                                                    }}
                                                  >
                                                    {platform}
                                                  </MenuItem>
                                                )
                                              }
                                            }
                                          )}
                                        </Select>
                                        {errors.platform && touched.platform ? (
                                          <div className={classes.errors}>
                                            {errors.platform}
                                          </div>
                                        ) : null}
                                      </FormControl>
                                    </GridItem>
                                    {values.format !== 'banner' ? (
                                      <GridItem xs={12} md={3}>
                                        <CustomInput
                                          labelText='Trigger(Clicks)'
                                          id='triggerInput'
                                          formControlProps={{
                                            fullWidth: true
                                          }}
                                          inputProps={{
                                            name: 'trigger',
                                            type: 'number',
                                            value: values.trigger,
                                            onChange: e => {
                                              setError('')
                                              values.adData[
                                                values.productIndex
                                              ].trigger = e.target.value
                                              setFieldValue(
                                                'trigger',
                                                e.target.value
                                              )
                                            }
                                          }}
                                        />
                                        {error && values.trigger === '' ? (
                                          <div className={classes.errors}>
                                            Required *
                                          </div>
                                        ) : null}
                                      </GridItem>
                                    ) : null}
                                    {values.format === 'banner' ? (
                                      <GridItem xs={12} sm={12} md={3}>
                                        <FormControl
                                          fullWidth
                                          className={classes.selectFormControl}
                                        >
                                          <InputLabel
                                            htmlFor='zone'
                                            className={classes.selectLabel}
                                          >
                                            Zone
                                          </InputLabel>
                                          <Select
                                            MenuProps={{
                                              className: classes.selectMenu
                                            }}
                                            classes={{
                                              select: classes.select
                                            }}
                                            value={values.zone}
                                            onChange={e => {
                                              setError('')
                                              values.adData[
                                                values.productIndex
                                              ].zone = e.target.value
                                              setFieldValue(
                                                'zone',
                                                e.target.value
                                              )
                                            }}
                                            inputProps={{
                                              name: 'zone',
                                              id: 'zoneSelect'
                                            }}
                                          >
                                            {zones.map((doc, index) => {
                                              return (
                                                <MenuItem
                                                  key={index}
                                                  value={doc}
                                                  classes={{
                                                    root: classes.selectMenuItem
                                                  }}
                                                >
                                                  {doc}
                                                </MenuItem>
                                              )
                                            })}
                                          </Select>
                                        </FormControl>
                                        {error && values.platform === '' ? (
                                          <div className={classes.errors}>
                                            Required *
                                          </div>
                                        ) : null}
                                      </GridItem>
                                    ) : null}
                                    {values.format === 'banner' ? (
                                      <GridItem xs={12} sm={12} md={3}>
                                        <FormControl
                                          fullWidth
                                          className={classes.selectFormControl}
                                        >
                                          <InputLabel
                                            htmlFor='bannerType'
                                            className={classes.selectLabel}
                                          >
                                            Banner Type
                                          </InputLabel>
                                          <Select
                                            MenuProps={{
                                              className: classes.selectMenu
                                            }}
                                            classes={{
                                              select: classes.select
                                            }}
                                            value={values.bannerType}
                                            onChange={e => {
                                              setError('')
                                              values.adData[
                                                values.productIndex
                                              ].bannerType = e.target.value
                                              setFieldValue(
                                                'bannerType',
                                                e.target.value
                                              )
                                            }}
                                            inputProps={{
                                              name: 'bannerType',
                                              id: 'bannerTypeSelect'
                                            }}
                                          >
                                            {bannerTypes.map((doc, index) => {
                                              return (
                                                <MenuItem
                                                  key={index}
                                                  value={doc}
                                                  classes={{
                                                    root: classes.selectMenuItem
                                                  }}
                                                >
                                                  {doc}
                                                </MenuItem>
                                              )
                                            })}
                                          </Select>
                                        </FormControl>
                                        {error && values.bannerType === '' ? (
                                          <div className={classes.errors}>
                                            Required *
                                          </div>
                                        ) : null}
                                      </GridItem>
                                    ) : null}
                                    {values.bannerType === 'internal' ? (
                                      <GridItem xs={12}>
                                        <GridContainer>
                                          <GridItem xs={12} sm={6}>
                                            <ImageUpload
                                              setFieldValue={setFieldValue}
                                              setError={setError}
                                              values={values}
                                            />
                                          </GridItem>
                                          <GridItem xs={12} sm={6}>
                                            {values.format === 'banner' ? (
                                              <GridItem
                                                xs={12}
                                                sm={12}
                                                style={{ marginTop: '2rem' }}
                                              >
                                                <CustomInput
                                                  labelText='Alt'
                                                  id='alt'
                                                  formControlProps={{
                                                    fullWidth: true
                                                  }}
                                                  inputProps={{
                                                    name: 'alt',
                                                    value: values.bannerAlt,
                                                    onChange: e => {
                                                      setError('')
                                                      values.adData[
                                                        values.productIndex
                                                      ].bannerAlt =
                                                        e.target.value
                                                      setFieldValue(
                                                        'bannerAlt',
                                                        e.target.value
                                                      )
                                                    }
                                                  }}
                                                />
                                                {error &&
                                                values.platform === '' ? (
                                                  <div
                                                    className={classes.errors}
                                                  >
                                                    Required *
                                                  </div>
                                                ) : null}
                                              </GridItem>
                                            ) : null}
                                          </GridItem>
                                        </GridContainer>

                                        {error && values.bannerImage === '' ? (
                                          <div className={classes.errors}>
                                            Required *
                                          </div>
                                        ) : null}
                                      </GridItem>
                                    ) : null}

                                    {values.format === 'preRoll' ? (
                                      <GridItem xs={12} sm={12} md={3} lg={3}>
                                        <CustomInput
                                          labelText='Exception'
                                          id='affiliate'
                                          formControlProps={{
                                            fullWidth: true
                                          }}
                                          inputProps={{
                                            name: 'affiliate',
                                            type: 'text',
                                            value: values.affiliate,
                                            onChange: e => {
                                              values.adData[
                                                values.productIndex
                                              ].affiliate = e.target.value
                                              setFieldValue(
                                                'affiliate',
                                                e.target.value
                                              )
                                            }
                                          }}
                                        />
                                      </GridItem>
                                    ) : null}

                                    {values.format === 'preRoll' ? (
                                      <GridItem xs={12}>
                                        <CustomInput
                                          labelText='URL'
                                          id='URL'
                                          formControlProps={{
                                            fullWidth: true
                                          }}
                                          inputProps={{
                                            name: 'URL',
                                            type: 'text',
                                            value: values.url,
                                            onChange: e => {
                                              values.adData[
                                                values.productIndex
                                              ].url = e.target.value
                                              setFieldValue(
                                                'url',
                                                e.target.value
                                              )
                                            }
                                          }}
                                        />
                                      </GridItem>
                                    ) : null}

                                    {values.format !== 'preRoll' &&
                                    values.format !== 'banner' ? (
                                      <GridItem xs={6} sm={6} md={3} lg={3}>
                                        <CustomInput
                                          labelText='Time Limit(Mins)'
                                          id='timeLimitInput'
                                          formControlProps={{
                                            fullWidth: true
                                          }}
                                          inputProps={{
                                            name: 'timeLimit',
                                            type: 'number',
                                            value: values.timeLimit,
                                            onChange: e => {
                                              values.adData[
                                                values.productIndex
                                              ].timeLimit = e.target.value
                                              setFieldValue(
                                                'timeLimit',
                                                e.target.value
                                              )
                                            }
                                          }}
                                        />
                                        {errors.timeLimit &&
                                        touched.timeLimit ? (
                                          <div className={classes.errors}>
                                            {errors.timeLimit}
                                          </div>
                                        ) : null}
                                      </GridItem>
                                    ) : null}
                                    {values.format !== 'preRoll' ? (
                                      <GridItem
                                        xs={
                                          values.format === 'popUnder' ? 9 : 12
                                        }
                                      >
                                        <CustomInput
                                          labelText={
                                            values.format === 'popUnder'
                                              ? values.popUnderType ===
                                                'redirect'
                                                ? 'Popunder URL'
                                                : values.popUnderType ===
                                                  'function'
                                                ? 'Function Body'
                                                : 'Script Src'
                                              : values.format === 'banner'
                                              ? values.bannerType === 'internal'
                                                ? 'Banner Redirect URL'
                                                : 'Banner Body'
                                              : 'Attached Variables'
                                          }
                                          id='bodyInput'
                                          formControlProps={{
                                            fullWidth: true
                                          }}
                                          inputProps={{
                                            name: 'body',
                                            value: values.body?.includes(
                                              'dt4URL'
                                            )
                                              ? values.body
                                                  .replace('dt4URL = ', '')
                                                  .replace('"', '')
                                                  .replace('"', '')
                                              : values.popUnderType ===
                                                'function'
                                              ? values.body.replace(/\\/gi, '')
                                              : values.body,
                                            onChange: e => {
                                              setError('')
                                              values.adData[
                                                values.productIndex
                                              ].body = e.target.value
                                              setFieldValue(
                                                'body',
                                                e.target.value
                                              )
                                            }
                                          }}
                                        />
                                        {errors.body && touched.body ? (
                                          <div className={classes.errors}>
                                            {errors.body}
                                          </div>
                                        ) : null}
                                      </GridItem>
                                    ) : null}

                                    {values.format === 'popUnder' ? (
                                      <GridItem xs={3}>
                                        <FormControl
                                          fullWidth
                                          className={classes.selectFormControl}
                                        >
                                          <InputLabel
                                            htmlFor='format'
                                            className={classes.selectLabel}
                                          >
                                            PopUnder Type
                                          </InputLabel>
                                          <Select
                                            MenuProps={{
                                              className: classes.selectMenu
                                            }}
                                            classes={{
                                              select: classes.select
                                            }}
                                            value={values.popUnderType}
                                            onChange={e => {
                                              setError('')
                                              values.adData[
                                                values.productIndex
                                              ].popUnderType = e.target.value

                                              setFieldValue(
                                                'popUnderType',
                                                e.target.value
                                              )
                                            }}
                                            inputProps={{
                                              name: 'popUnderType',
                                              id: 'popUnderType'
                                            }}
                                          >
                                            {[
                                              'redirect',
                                              'function',
                                              'script'
                                            ].map((doc, index) => {
                                              if (doc === values.popUnderType) {
                                                return (
                                                  <MenuItem
                                                    key={index}
                                                    value={doc}
                                                    classes={{
                                                      root:
                                                        classes.selectMenuItem
                                                    }}
                                                    defaultValue
                                                  >
                                                    {doc}
                                                  </MenuItem>
                                                )
                                              } else {
                                                return (
                                                  <MenuItem
                                                    key={index}
                                                    value={doc}
                                                    classes={{
                                                      root:
                                                        classes.selectMenuItem
                                                    }}
                                                  >
                                                    {doc}
                                                  </MenuItem>
                                                )
                                              }
                                            })}
                                          </Select>
                                        </FormControl>
                                      </GridItem>
                                    ) : null}
                                  </GridContainer>
                                </div>
                              ) : (
                                <h3
                                  style={{
                                    color: infoColor[0],
                                    textAlign: 'center'
                                  }}
                                >
                                  {' '}
                                  Select a product to view details
                                </h3>
                              )}
                            </CardBody>
                          </Card>
                        ) : null}
                      </GridItem>
                      <GridItem xs={12}>
                        {showProducts &&
                        values.source &&
                        values.format &&
                        values.platform &&
                        (values.format === 'banner' ? values.zone : true) ? (
                          <div>
                            <Card
                              style={{
                                backgroundColor: '#F7F7F7',
                                zIndex: 0
                              }}
                            >
                              <CardHeader style={{ textAlign: 'center' }}>
                                <h5>Available Products</h5>
                                <p style={{ color: infoColor[0] }}>
                                  {' '}
                                  Click on a product to add it to your campaign
                                </p>
                                {values.adData.length > 0 ? (
                                  <Button
                                    justIcon
                                    className={classes.modalCloseButton}
                                    style={{
                                      position: 'absolute',
                                      top: '8px',
                                      right: 0
                                    }}
                                    key='close'
                                    aria-label='Close'
                                    color='transparent'
                                    onClick={e => {
                                      setShowProducts(false)
                                    }}
                                  >
                                    <Close
                                      className={classes.modalClose}
                                      style={{ color: '#000' }}
                                    />
                                  </Button>
                                ) : null}
                              </CardHeader>
                              <CardBody>
                                <GridContainer>
                                  {values.format === 'interstitial' ||
                                  values.format === 'popUnder' ? (
                                    products.filter(
                                      product =>
                                        product.trigger === trigger &&
                                        product.format === values.format &&
                                        product.platform === values.platform
                                    ).length > 0 ? (
                                      products
                                        .filter(
                                          product =>
                                            product.trigger === trigger &&
                                            product.format === values.format &&
                                            product.platform === values.platform
                                        )
                                        .map((product, index) => {
                                          const includedProducts = values.adData.map(
                                            product => product.name
                                          )
                                          const allProducts = products
                                            .filter(
                                              product =>
                                                product.format ===
                                                  values.format &&
                                                product.platform ===
                                                  values.platform &&
                                                product.trigger === trigger
                                            )
                                            .map(product => product.name)
                                          const productsMap = allProducts.map(
                                            product => {
                                              if (
                                                includedProducts.includes(
                                                  product
                                                )
                                              ) {
                                                return 1
                                              } else {
                                                return 0
                                              }
                                            }
                                          )

                                          if (Math.min(...productsMap) === 1) {
                                            if (index === 0) {
                                              return (
                                                <div
                                                  className={classes.errors}
                                                  key={index}
                                                  style={{
                                                    width: '100%',
                                                    textAlign: 'center'
                                                  }}
                                                >
                                                  <p>
                                                    No products available for
                                                    current parameters:{' '}
                                                  </p>
                                                  <p>
                                                    {values.format !== ''
                                                      ? `Format: ${values.format}`
                                                      : null}{' '}
                                                  </p>
                                                  <p>
                                                    {values.platform !== ''
                                                      ? `Platform: ${values.platform}`
                                                      : null}{' '}
                                                  </p>
                                                  <p>Trigger: {trigger}</p>
                                                </div>
                                              )
                                            } else {
                                              return null
                                            }
                                          } else if (
                                            includedProducts.includes(
                                              product.name
                                            )
                                          ) {
                                            return null
                                          } else {
                                            return (
                                              <GridItem
                                                key={index}
                                                xs={3}
                                                sm={3}
                                              >
                                                <Button
                                                  fullWidth
                                                  style={{
                                                    padding: '8px',
                                                    margin: '8px 8px 8px 0',
                                                    borderRadius: '5px'
                                                  }}
                                                  color='info'
                                                  onClick={e => {
                                                    const allProducts = JSON.parse(
                                                      JSON.stringify(
                                                        values.adData
                                                      )
                                                    )
                                                    allProducts.push(product)
                                                    setFieldValue(
                                                      'adData',
                                                      allProducts
                                                    )
                                                    const n = allProducts.length
                                                    setFieldValue(
                                                      'productIndex',
                                                      n - 1
                                                    )
                                                    setFieldValue(
                                                      'productName',
                                                      product.name
                                                    )
                                                    setFieldValue(
                                                      'distPercentage',
                                                      product.distPercentage
                                                        ? product.distPercentage
                                                        : 0
                                                    )
                                                    setFieldValue(
                                                      'format',
                                                      product.format
                                                    )
                                                    setFieldValue(
                                                      'platform',
                                                      product.platform
                                                    )
                                                    setFieldValue(
                                                      'provider',
                                                      product.provider
                                                    )
                                                    setFieldValue(
                                                      'zone',
                                                      product.zone
                                                    )
                                                    setFieldValue(
                                                      'bannerType',
                                                      product.bannerType
                                                    )
                                                    setFieldValue(
                                                      'bannerImage',
                                                      product.bannerImage
                                                    )
                                                    setFieldValue(
                                                      'bannerAlt',
                                                      product.bannerAlt
                                                    )
                                                    setFieldValue(
                                                      'trigger',
                                                      product.trigger
                                                    )
                                                    setFieldValue(
                                                      'affiliate',
                                                      product.affiliate
                                                    )
                                                    setFieldValue(
                                                      'url',
                                                      product.url
                                                    )
                                                    setFieldValue(
                                                      'originURL',
                                                      product
                                                    )
                                                    setFieldValue(
                                                      'body',
                                                      product.body
                                                    )
                                                    setFieldValue(
                                                      'timeLimit',
                                                      product.timeLimit
                                                    )
                                                    setFieldValue(
                                                      'popUnderType',
                                                      product.popUnderType
                                                    )
                                                  }}
                                                >
                                                  {product.name.length > 20
                                                    ? product.name.slice(
                                                        0,
                                                        16
                                                      ) + '...'
                                                    : product.name}
                                                </Button>
                                              </GridItem>
                                            )
                                          }
                                        })
                                    ) : (
                                      <div
                                        className={classes.errors}
                                        style={{
                                          width: '100%',
                                          textAlign: 'center'
                                        }}
                                      >
                                        <p>
                                          No products available for current
                                          parameters:{' '}
                                        </p>

                                        <p>
                                          {values.format !== ''
                                            ? `Format: ${values.format}`
                                            : null}{' '}
                                        </p>
                                        <p>
                                          {values.platform !== ''
                                            ? `Platform: ${values.platform}`
                                            : null}{' '}
                                        </p>
                                        <p>Trigger: {trigger}</p>
                                      </div>
                                    )
                                  ) : values.format === 'preRoll' ? (
                                    products.filter(
                                      product =>
                                        product.format === values.format &&
                                        product.platform === values.platform
                                    ).length > 0 ? (
                                      products
                                        .filter(
                                          product =>
                                            product.format === values.format &&
                                            product.platform === values.platform
                                        )
                                        .map((product, index) => {
                                          return (
                                            <GridItem key={index} xs={3} sm={3}>
                                              <Button
                                                fullWidth
                                                style={{
                                                  padding: '8px',
                                                  margin: '8px 8px 8px 0',
                                                  borderRadius: '5px'
                                                }}
                                                color='info'
                                                onClick={e => {
                                                  const allProducts = JSON.parse(
                                                    JSON.stringify(
                                                      values.adData
                                                    )
                                                  )
                                                  allProducts.push(product)
                                                  setFieldValue(
                                                    'adData',
                                                    allProducts
                                                  )
                                                  const n = allProducts.length
                                                  setFieldValue(
                                                    'productIndex',
                                                    n - 1
                                                  )
                                                  setFieldValue(
                                                    'productName',
                                                    product.name
                                                  )
                                                  setFieldValue(
                                                    'distPercentage',
                                                    product.distPercentage
                                                      ? product.distPercentage
                                                      : 0
                                                  )
                                                  setFieldValue(
                                                    'format',
                                                    product.format
                                                  )
                                                  setFieldValue(
                                                    'platform',
                                                    product.platform
                                                  )
                                                  setFieldValue(
                                                    'provider',
                                                    product.provider
                                                  )
                                                  setFieldValue(
                                                    'zone',
                                                    product.zone
                                                  )
                                                  setFieldValue(
                                                    'bannerType',
                                                    product.bannerType
                                                  )
                                                  setFieldValue(
                                                    'bannerImage',
                                                    product.bannerImage
                                                  )
                                                  setFieldValue(
                                                    'bannerAlt',
                                                    product.bannerAlt
                                                  )
                                                  setFieldValue(
                                                    'trigger',
                                                    product.trigger
                                                  )
                                                  setFieldValue(
                                                    'affiliate',
                                                    product.affiliate
                                                  )
                                                  setFieldValue(
                                                    'url',
                                                    product.url
                                                  )
                                                  setFieldValue(
                                                    'originURL',
                                                    product
                                                  )
                                                  setFieldValue(
                                                    'body',
                                                    product.body
                                                  )
                                                  setFieldValue(
                                                    'timeLimit',
                                                    product.timeLimit
                                                  )
                                                  setFieldValue(
                                                    'popUnderType',
                                                    product.popUnderType
                                                  )
                                                }}
                                              >
                                                {product.name.length > 20
                                                  ? product.name.slice(0, 16) +
                                                    '...'
                                                  : product.name}
                                              </Button>
                                            </GridItem>
                                          )
                                        })
                                    ) : (
                                      <div
                                        className={classes.errors}
                                        style={{
                                          width: '100%',
                                          textAlign: 'center'
                                        }}
                                      >
                                        No products available for current
                                        parameters:{' '}
                                        {values.format !== ''
                                          ? `Format: ${values.format}`
                                          : null}{' '}
                                        {values.platform !== ''
                                          ? `Platform: ${values.platform}`
                                          : null}{' '}
                                        {values.zone !== ''
                                          ? `Zone: ${values.zone}`
                                          : null}
                                      </div>
                                    )
                                  ) : products.filter(
                                      product =>
                                        product.format === values.format &&
                                        product.zone === values.zone &&
                                        product.platform === values.platform
                                    ) ? (
                                    products
                                      .filter(
                                        product =>
                                          product.format === values.format &&
                                          product.zone === values.zone &&
                                          product.platform === values.platform
                                      )
                                      .map((product, index) => {
                                        const includedProducts = values.adData.map(
                                          product => product.name
                                        )
                                        if (
                                          includedProducts.includes(
                                            product.name
                                          )
                                        ) {
                                          return null
                                        } else {
                                          return (
                                            <GridItem key={index} xs={3} sm={3}>
                                              <Button
                                                fullWidth
                                                style={{
                                                  padding: '8px',
                                                  margin: '8px 8px 8px 0',
                                                  borderRadius: '5px'
                                                }}
                                                color='info'
                                                onClick={e => {
                                                  const allProducts = JSON.parse(
                                                    JSON.stringify(
                                                      values.adData
                                                    )
                                                  )
                                                  allProducts.push(product)
                                                  setFieldValue(
                                                    'adData',
                                                    allProducts
                                                  )
                                                  const n = allProducts.length
                                                  setFieldValue(
                                                    'productIndex',
                                                    n - 1
                                                  )
                                                  setFieldValue(
                                                    'productName',
                                                    product.name
                                                  )
                                                  setFieldValue(
                                                    'distPercentage',
                                                    product.distPercentage
                                                      ? product.distPercentage
                                                      : 0
                                                  )
                                                  setFieldValue(
                                                    'format',
                                                    product.format
                                                  )
                                                  setFieldValue(
                                                    'platform',
                                                    product.platform
                                                  )
                                                  setFieldValue(
                                                    'provider',
                                                    product.provider
                                                  )
                                                  setFieldValue(
                                                    'zone',
                                                    product.zone
                                                  )
                                                  setFieldValue(
                                                    'bannerType',
                                                    product.bannerType
                                                  )
                                                  setFieldValue(
                                                    'bannerImage',
                                                    product.bannerImage
                                                  )
                                                  setFieldValue(
                                                    'bannerAlt',
                                                    product.bannerAlt
                                                  )
                                                  setFieldValue(
                                                    'trigger',
                                                    product.trigger
                                                  )
                                                  setFieldValue(
                                                    'affiliate',
                                                    product.affiliate
                                                  )
                                                  setFieldValue(
                                                    'url',
                                                    product.url
                                                  )
                                                  setFieldValue(
                                                    'originURL',
                                                    product
                                                  )
                                                  setFieldValue(
                                                    'body',
                                                    product.body
                                                  )
                                                  setFieldValue(
                                                    'timeLimit',
                                                    product.timeLimit
                                                  )
                                                  setFieldValue(
                                                    'popUnderType',
                                                    product.popUnderType
                                                  )
                                                }}
                                              >
                                                {product.name.length > 20
                                                  ? product.name.slice(0, 16) +
                                                    '...'
                                                  : product.name}
                                              </Button>
                                            </GridItem>
                                          )
                                        }
                                      })
                                  ) : (
                                    <div
                                      className={classes.errors}
                                      style={{
                                        width: '100%',
                                        textAlign: 'center'
                                      }}
                                    >
                                      <p>
                                        No products available for current
                                        parameters:{' '}
                                      </p>
                                      <p>
                                        {values.format !== ''
                                          ? `Format: ${values.format}`
                                          : null}{' '}
                                      </p>
                                      <p>
                                        {values.platform !== ''
                                          ? `Platform: ${values.platform}`
                                          : null}{' '}
                                      </p>
                                      <p>
                                        {values.zone !== ''
                                          ? `Zone: ${values.zone}`
                                          : null}
                                      </p>
                                    </div>
                                  )}
                                </GridContainer>
                              </CardBody>
                            </Card>
                          </div>
                        ) : null}
                      </GridItem>

                      <GridItem
                        xs={12}
                        style={{
                          borderTop: '1px solid #EEEEEE',
                          marginTop: '1rem'
                        }}
                      >
                        <GridContainer style={{ marginTop: '1rem' }}>
                          <GridItem xs={12} sm={12} md={9}>
                            {error ? (
                              <div className={classes.errors}>{error}</div>
                            ) : null}
                          </GridItem>
                          <GridItem xs={12} sm={12} md={3}>
                            {values.index !== -1 && (
                              <Button
                                type='submit'
                                color='primary'
                                fullWidth
                                onClick={e => {
                                  e.preventDefault()
                                  handleSubmit(values, resetForm)
                                }}
                              >
                                Save Changes
                                <SaveIcon
                                  style={{
                                    marginLeft: '0.5rem',
                                    color: infoColor[0]
                                  }}
                                />
                              </Button>
                            )}
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </GridContainer>
                  </Form>
                </>
              ) : (
                <CircularProgress />
              )}
            </DialogContent>
          </Dialog>
        )}
      </Formik>
    </div>
  )
}

Campaign.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  selectedCampaign: PropTypes.object,
  setSelectedCampaign: PropTypes.func,
  products: PropTypes.array.isRequired,
  sources: PropTypes.array.isRequired,
  formats: PropTypes.array.isRequired,
  scriptIds: PropTypes.array.isRequired,
  refreshCampaigns: PropTypes.func.isRequired,
  campaignUpdated: PropTypes.func.isRequired
}

export default Campaign
