import fb from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
const config = {
  apiKey: 'AIzaSyBbVtyQFT1yHbknX9dZ2XdGP4dYGwWqT_Y',
  authDomain: 'dt4ever-85e50.firebaseapp.com',
  databaseURL: 'https://dt4ever-85e50.firebaseio.com',
  projectId: 'dt4ever-85e50',
  storageBucket: 'dt4ever-85e50.appspot.com',
  messagingSenderId: '816689218239',
  appId: '1:816689218239:web:d02d6d7b0ec539c647bc0a',
  measurementId: 'G-NBP7SQSVPG'
}
const firebase = fb.initializeApp(config)
export default firebase
