import React, { useState, useEffect } from 'react'

// React-table
import ReactTable from 'react-table'

// Custom Components
import Campaign from 'components/Campaign/Campaign'

// Material UI components
import { makeStyles } from '@material-ui/core/styles'

// Core Components
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import Button from 'components/CustomButtons/Button'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Danger from 'components/Typography/Danger'

// Material UI Icons
import LibraryAdd from '@material-ui/icons/LibraryAdd'
import DeleteForever from '@material-ui/icons/DeleteForever'
import EditIcon from '@material-ui/icons/Edit'
import Done from '@material-ui/icons/Done'
import HighlightOff from '@material-ui/icons/HighlightOff'

// Sweet Alert
import SweetAlert from 'react-bootstrap-sweetalert'

import alertStyles from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle'

// Axios
import axios from 'axios'

import { roseColor } from 'assets/jss/material-dashboard-pro-react.js'

const useAlertStyles = makeStyles(alertStyles)

const Campaigns = () => {
  // State
  const [campaigns, setCampaigns] = useState([])
  const [alert, setAlert] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [selectedCampaign, setSelectedCampaign] = useState(null)

  const [scriptIds, setScriptIds] = useState([])
  const [sources, setSources] = useState([])
  const [products, setProducts] = useState([])
  // Options
  const formats = ['interstitial', 'popUnder', 'preRoll', 'banner']
  const alertClasses = useAlertStyles()

  // Methods
  const hideAlert = () => {
    setAlert(null)
  }

  const handleClick = (e, row) => {
    let campaign = null
    if (row && row.scriptData.length > 0) {
      campaign = JSON.parse(JSON.stringify(row))
      campaign.scriptData.map(product => {
        product.timeLimit = (product.timeLimit * 24 * 60).toFixed(0)
        return product
      })
    }

    setSelectedCampaign(campaign)
    setOpenModal(true)
  }

  const refreshCampaigns = () => {
    axios
      .get('https://api.dt4ever.com/scripts')
      .then(res => {
        const newData = res.data.map(doc => {
          const temp = res.data.filter(
            script => script.scriptId === doc.scriptId
          )
          return {
            ...doc,
            scriptId: doc.scriptId + temp.indexOf(doc).toString()
          }
        })

        setCampaigns(newData.sort((a, b) => a.scriptId - b.scriptId))
      })
      .catch(e => console.log(e))
    axios
      .get('https://api-preroll.dt4ever.com/scripts')
      .then(res => console.log('Updated preroll-api successfully'))
      .catch(e => console.log('Unable to update preroll api'))
    axios
      .get('https://api-banner.dt4ever.com/scripts')
      .then(res => console.log('Updated banner-api successfully'))
      .catch(e => console.log('Unable to update banner api'))
  }
  const campaignUpdated = () => {
    setAlert(
      <SweetAlert
        success
        style={{
          display: 'block',
          marginTop: '-100px'
        }}
        title='Campaigns Updated'
        onConfirm={() => {
          hideAlert()
        }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={alertClasses.button + ' ' + alertClasses.success}
        cancelBtnCssClass={alertClasses.button + ' ' + alertClasses.danger}
        showConfirm={false}
      />
    )
    setTimeout(() => {
      hideAlert()
    }, 1000)
  }

  // Effects
  useEffect(() => {
    axios
      .get('https://api.dt4ever.com/scripts')
      .then(res => {
        const newData = res.data.map(doc => {
          const temp = res.data.filter(
            script => script.scriptId === doc.scriptId
          )
          return {
            ...doc,
            scriptId: doc.scriptId + temp.indexOf(doc).toString()
          }
        })

        setCampaigns(newData.sort((a, b) => a.scriptId - b.scriptId))
        axios
          .get('https://api.dt4ever.com/products')
          .then(res => {
            setProducts(
              res.data.map(product => {
                product.timeLimit = (product.timeLimit * 24 * 60).toFixed(0)
                return product
              })
            )
          })
          .catch(e => console.log(e))
      })
      .catch(e => console.log(e))
    axios
      .get('https://api-preroll.dt4ever.com/scripts')
      .then(res => console.log('Updated preroll-api successfully'))
      .catch(e => console.log('Unable to update preroll api'))
    axios
      .get('https://api-banner.dt4ever.com/scripts')
      .then(res => console.log('Updated banner-api successfully'))
      .catch(e => console.log('Unable to update banner api'))
  }, [])
  useEffect(() => {
    setScriptIds(
      campaigns.map(doc => {
        const parsedId = doc.scriptId.toString('').split('')
        parsedId.pop()
        return {
          scriptId: parsedId.join(''),
          source: doc.source,
          format: doc.scriptData[0]?.format
        }
      })
    )
    setSources([...new Set(campaigns.map(doc => doc.source))])
  }, [campaigns])

  return (
    <GridContainer>
      <GridItem xs={12}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            color='transparent'
            onClick={handleClick}
            value='new'
            style={{
              color: '#0d47a1',
              fontSize: '0.8rem',
              marginTop: '1.5rem',
              padding: '0.5rem',
              border: '1.2px solid #0d47a1',
              borderRadius: '8px'
            }}
          >
            <LibraryAdd /> New Campaign
          </Button>
        </div>
      </GridItem>
      <GridItem xs={12}>
        <Card>
          <CardBody>
            {alert}
            <ReactTable
              data={campaigns}
              loading={!(campaigns.length > 0)}
              filterable
              getTrProps={(state, rowInfo, column) => {
                return {
                  style: {
                    height: '40px',
                    overflow: 'hidden'
                  }
                }
              }}
              sortable={false}
              columns={[
                {
                  Header: 'Campaign ID',
                  accessor: 'scriptId',
                  sortable: true,
                  width: 150,
                  style: {
                    fontWeight: 'bold'
                  },
                  headerStyle: {
                    fontWeight: 'bold'
                  }
                },
                {
                  Header: 'Name',
                  accessor: 'name',
                  width: 200,
                  style: {
                    fontWeight: 'bold'
                  },
                  headerStyle: {
                    fontWeight: 'bold'
                  }
                },
                {
                  Header: 'Source',
                  accessor: 'source',
                  width: 150,
                  style: {
                    color: roseColor[0]
                  },
                  headerStyle: {
                    fontWeight: 'bold'
                  }
                },
                {
                  Header: 'Format',
                  filterable: false,
                  Cell: ({ row }) => {
                    return <> {row._original.scriptData[0]?.format} </>
                  },
                  headerStyle: {
                    fontWeight: 'bold'
                  }
                },
                {
                  Header: 'Active',
                  accessor: 'isActive',
                  filterable: false,
                  maxWidth: 100,
                  Cell: ({ row }) => {
                    return row.isActive ? (
                      <Button
                        color='success'
                        justIcon
                        simple
                        size='sm'
                        style={{
                          margin: 0
                        }}
                        onClick={e => {
                          setAlert(
                            <SweetAlert
                              danger
                              style={{
                                display: 'block',
                                marginTop: '-100px'
                              }}
                              title='Disable Campaign'
                              onConfirm={e => {
                                const newAd = row._original
                                newAd.isActive = false
                                newAd.distPercentage = 0
                                newAd.scriptData = newAd.scriptData.map(doc => {
                                  doc.timeLimit = doc.timeLimit / 24 / 60
                                  return doc
                                })
                                const parsedId = newAd.scriptId
                                  .toString()
                                  .split('')
                                parsedId.pop()

                                axios
                                  .put(
                                    `https://api.dt4ever.com/scripts/${parsedId.join(
                                      ''
                                    )}/${newAd._id}`,
                                    newAd
                                  )
                                  .then(res => {
                                    refreshCampaigns()
                                  })
                                  .catch(e => console.log(e))
                                hideAlert()
                              }}
                              value='Disable'
                              onCancel={() => hideAlert()}
                              confirmBtnCssClass={
                                alertClasses.button + ' ' + alertClasses.success
                              }
                              cancelBtnCssClass={
                                alertClasses.button + ' ' + alertClasses.danger
                              }
                              showCancel
                              confirmBtnText='YES'
                              cancelBtnText='NO'
                            >
                              Are you sure you want to disable this campaign?
                            </SweetAlert>
                          )
                        }}
                      >
                        {' '}
                        <Done />{' '}
                      </Button>
                    ) : (
                      <Button
                        color='danger'
                        justIcon
                        simple
                        size='sm'
                        style={{
                          margin: 0
                        }}
                        onClick={e => {
                          setAlert(
                            <SweetAlert
                              success
                              style={{
                                display: 'block',
                                marginTop: '-100px'
                              }}
                              title='Enable Campaign'
                              onConfirm={e => {
                                const newAd = row._original
                                newAd.isActive = true
                                newAd.scriptData = newAd.scriptData.map(doc => {
                                  doc.timeLimit = doc.timeLimit / 24 / 60
                                  return doc
                                })
                                const parsedId = newAd.scriptId
                                  .toString()
                                  .split('')
                                parsedId.pop()

                                axios
                                  .put(
                                    `https://api.dt4ever.com/scripts/${parsedId.join(
                                      ''
                                    )}/${newAd._id}`,
                                    newAd
                                  )
                                  .then(res => {
                                    refreshCampaigns()
                                  })
                                  .catch(e => console.log(e))
                                hideAlert()
                              }}
                              value='Enable'
                              onCancel={() => hideAlert()}
                              confirmBtnCssClass={
                                alertClasses.button + ' ' + alertClasses.success
                              }
                              cancelBtnCssClass={
                                alertClasses.button + ' ' + alertClasses.danger
                              }
                              showCancel
                              confirmBtnText='YES'
                              cancelBtnText='NO'
                            >
                              Are you sure you want to enable this campaign?
                            </SweetAlert>
                          )
                        }}
                      >
                        {' '}
                        <HighlightOff />{' '}
                      </Button>
                    )
                  },
                  headerStyle: {
                    fontWeight: 'bold',
                    textAlign: 'right'
                  },
                  style: {
                    textAlign: 'center'
                  }
                },
                {
                  Header: 'Actions',
                  filterable: false,
                  maxWidth: 150,
                  Cell: ({ row }) => {
                    return (
                      <div>
                        <Button
                          color='info'
                          justIcon
                          size='sm'
                          style={{
                            margin: '0 1rem 0 0'
                          }}
                          onClick={e => {
                            handleClick(e, row._original)
                          }}
                          value='edit'
                          simple
                        >
                          <EditIcon />
                        </Button>

                        <Button
                          color='danger'
                          simple
                          size='sm'
                          justIcon
                          style={{
                            margin: 0
                          }}
                          onClick={e => {
                            if (row._original.distPercentage > 0) {
                              setAlert(
                                <SweetAlert
                                  danger
                                  style={{
                                    display: 'block',
                                    marginTop: '-100px'
                                  }}
                                  title='Campaign in use'
                                  value='delete'
                                  onCancel={() => hideAlert()}
                                  showConfirm={false}
                                >
                                  <Danger>
                                    Please remove {row._original.distPercentage}
                                    % from distribution to delete this campaign
                                  </Danger>
                                </SweetAlert>
                              )
                              setTimeout(() => {
                                hideAlert()
                              }, 3000)
                            } else {
                              setAlert(
                                <SweetAlert
                                  danger
                                  style={{
                                    display: 'block',
                                    marginTop: '-100px'
                                  }}
                                  title='Delete Campaign'
                                  onConfirm={e => {
                                    const scriptId = row._original.scriptId
                                      .toString()
                                      .split('')
                                    scriptId.pop()
                                    axios
                                      .delete(
                                        `https://api.dt4ever.com/scripts/${scriptId.join(
                                          ''
                                        )}/${row._original._id}`
                                      )
                                      .then(res => {
                                        axios
                                          .get(
                                            'https://api.dt4ever.com/scripts'
                                          )
                                          .then(res => {
                                            campaignUpdated()
                                            const newData = res.data.map(
                                              doc => {
                                                const temp = res.data.filter(
                                                  script =>
                                                    script.scriptId ===
                                                    doc.scriptId
                                                )
                                                return {
                                                  ...doc,
                                                  scriptId:
                                                    doc.scriptId +
                                                    temp.indexOf(doc).toString()
                                                }
                                              }
                                            )

                                            setCampaigns(
                                              newData
                                                .map(doc => {
                                                  doc.scriptData.forEach(
                                                    script =>
                                                      (script.timeLimit = (
                                                        script.timeLimit *
                                                        24 *
                                                        60
                                                      ).toFixed(0))
                                                  )
                                                  return doc
                                                })
                                                .sort(
                                                  (a, b) =>
                                                    a.scriptId - b.scriptId
                                                )
                                                .map(doc => {
                                                  doc.scriptData.forEach(
                                                    script =>
                                                      (script.timeLimit = (
                                                        script.timeLimit *
                                                        24 *
                                                        60
                                                      ).toFixed(2))
                                                  )
                                                  return doc
                                                })
                                            )
                                            setTimeout(() => {
                                              hideAlert()
                                            }, 1500)
                                          })
                                          .catch(e => console.log(e))
                                      })
                                      .catch(e => console.log(e))
                                    refreshCampaigns()
                                  }}
                                  value='delete'
                                  onCancel={() => hideAlert()}
                                  confirmBtnCssClass={
                                    alertClasses.button +
                                    ' ' +
                                    alertClasses.success
                                  }
                                  cancelBtnCssClass={
                                    alertClasses.button +
                                    ' ' +
                                    alertClasses.danger
                                  }
                                  showCancel
                                  confirmBtnText='YES'
                                  cancelBtnText='NO'
                                >
                                  Are you sure you want to delete this campaign?
                                </SweetAlert>
                              )
                            }
                          }}
                          value='delete'
                        >
                          <DeleteForever />
                        </Button>
                      </div>
                    )
                  },
                  headerStyle: {
                    textAlign: 'right',
                    paddingLeft: '2rem',
                    fontWeight: 'bold',
                    marginRight: '1rem'
                  },
                  style: {
                    textAlign: 'right'
                  }
                }
              ]}
              defaultPageSize={10}
              className='-striped -highlight'
              showPaginationBottom
              style={{ zIndex: 0 }}
            />
          </CardBody>
        </Card>
      </GridItem>
      {openModal ? (
        <Campaign
          campaigns={campaigns}
          openModal={openModal}
          setOpenModal={setOpenModal}
          selectedCampaign={selectedCampaign}
          setSelectedCampaign={setSelectedCampaign}
          products={products}
          sources={sources}
          formats={formats}
          scriptIds={scriptIds}
          refreshCampaigns={refreshCampaigns}
          campaignUpdated={campaignUpdated}
        />
      ) : null}
    </GridContainer>
  )
}

export default Campaigns
