import React, { useEffect } from 'react'
// used for making the prop types of this component
import PropTypes from 'prop-types'

// axios
import axios from 'axios'

import defaultImage from 'assets/img/image_placeholder.jpg'
import defaultAvatar from 'assets/img/placeholder.jpg'

const ImageUpload = props => {
  let {
    avatar,

    setFieldValue,
    setError,
    values
  } = props
  const [file, setFile] = React.useState(
    values.bannerImage ? values.bannerImage : null
  )
  const [filename, setFilename] = React.useState(null)
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
    values.bannerImage ? values.bannerImage : defaultImage
  )
  let fileInput = React.createRef()
  const handleImageChange = e => {
    e.preventDefault()
    let reader = new FileReader()
    let file = e.target.files[0]
    reader.onloadend = () => {
      setFile(file)
      setImagePreviewUrl(reader.result)
      axios
        .post('https://api.dt4ever.com/image', {
          image: reader.result,
          name: file.name
        })
        .then(res => {
          console.log(res)
          setError('')
          if (values.adData) {
            values.adData[values.index].bannerImage = res.data.data
          }

          setFieldValue('bannerImage', res.data.data)
          setFilename(res.data.filename)
          setImagePreviewUrl(reader.result)
        })
        .catch(e => console.log(e))
    }
    reader.readAsDataURL(file)
  }
  // eslint-disable-next-line
  const handleClick = () => {
    fileInput.current.click()
  }
  const handleRemove = () => {
    setFile(null)
    if (filename) {
      axios
        .get(`https://api.dt4ever.com/image/${filename}`)
        .then(res => {
          setError('')
          setFilename(null)
        })
        .catch(e => console.log(e))
    }

    setImagePreviewUrl(props.avatar ? defaultAvatar : defaultImage)
    if (values.adData) {
      values.adData[values.index].bannerImage = null
    }
    setFieldValue('bannerImage', null)
    fileInput.current.value = null
  }

  useEffect(() => {
    if (values.bannerImage) {
      setImagePreviewUrl(values.bannerImage)
    }
  }, [values])

  return (
    <div
      className='fileinput text-center '
      onClick={() => (file === null ? handleClick() : handleRemove())}
      style={{ cursor: 'pointer' }}
    >
      <input type='file' onChange={handleImageChange} ref={fileInput} />

      <div className={'thumbnail' + (avatar ? ' img-circle' : '')}>
        {file !== null ? (
          <i
            className='fas fa-times'
            style={{
              position: 'absolute',
              fontSize: '8rem',
              marginLeft: '-2.5rem',
              marginTop: '1rem',
              opacity: 0.9
            }}
          ></i>
        ) : null}

        <img src={imagePreviewUrl} alt='...' />
      </div>
    </div>
  )
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object
}

export default ImageUpload
