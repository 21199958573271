import React from 'react'
// react component for creating dynamic tables
import ReactTable from 'react-table'

// core components
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'

// Moment
import moment from 'moment'

import {
  roseColor,
  infoColor
} from 'assets/jss/material-dashboard-pro-react.js'

const CustomTable = props => {
  const numberWithCommas = x => {
    var parts = x.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    return parts.join('.')
  }

  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardBody>
            <ReactTable
              data={props.tableData}
              loading={props.loading}
              filterable
              sortable={false}
              defaultFilterMethod={(filter, row) =>
                filter.value.includes('-')
                  ? String(row[filter.id]) ===
                    filter.value
                      .split('-')
                      .reverse()
                      .join('')
                  : String(row[filter.id])
                      .toLowerCase()
                      .startsWith(filter.value.toLowerCase())
              }
              columns={
                props.viewDetails
                  ? [
                      {
                        Header: 'Date',
                        accessor: 'date',
                        sortable: true,
                        filterable: false,
                        style: {
                          fontWeight: 'bold'
                        },
                        maxWidth: 100,
                        Cell: ({ row }) => {
                          return moment(row.date.toString()).format(
                            'DD-MM-YYYY'
                          )
                        },
                        headerStyle: {
                          fontWeight: 'bold'
                        }
                      },
                      {
                        Header: 'Provider',
                        accessor: 'provider',
                        width: 150,
                        headerStyle: {
                          fontWeight: 'bold'
                        }
                      },
                      {
                        Header: 'Source',
                        accessor: 'source',
                        maxWidth: 100,
                        style: {
                          color: roseColor[4]
                        },
                        headerStyle: {
                          fontWeight: 'bold'
                        }
                      },
                      {
                        Header: 'Format',
                        accessor: 'format',
                        maxWidth: 100,
                        headerStyle: {
                          fontWeight: 'bold'
                        }
                      },
                      {
                        Header: 'Platform',
                        accessor: 'platform',
                        maxWidth: 100,
                        headerStyle: {
                          fontWeight: 'bold'
                        }
                      },
                      {
                        Header: 'Country',
                        accessor: 'country',
                        maxWdith: 100,
                        headerStyle: {
                          fontWeight: 'bold'
                        },
                        Cell: ({ row }) => {
                          return row._original.country
                            ? row._original.country
                            : 'none'
                        }
                      },
                      {
                        Header: 'Af',
                        accessor: 'affiliate',
                        maxWidth: 100,
                        headerStyle: {
                          textAlign: 'center',
                          fontWeight: 'bold'
                        }
                      },
                      {
                        Header: 'utmSource',
                        accessor: 'utmSource',
                        maxWidth: 100,
                        headerStyle: {
                          fontWeight: 'bold'
                        }
                      },
                      {
                        Header: 'Medium',
                        accessor: 'utmMedium',
                        maxWidth: 100,
                        headerStyle: {
                          fontWeight: 'bold'
                        }
                      },
                      {
                        Header: 'Campaign',
                        accessor: 'utmCampaign',
                        maxWidth: 100,
                        headerStyle: {
                          fontWeight: 'bold'
                        }
                      },
                      {
                        Header: 'Prints',
                        accessor: 'prints',
                        sortable: false,
                        filterable: false,
                        Cell: ({ row }) => {
                          return row.prints ? numberWithCommas(row.prints) : 0
                        },
                        Footer: info => {
                          return numberWithCommas(
                            info.data.reduce(
                              (sum, el) =>
                                el.prints ? (sum += el.prints) : (sum += 0),
                              0
                            ) || 0
                          )
                        },
                        maxWidth: 100,
                        style: {
                          color: infoColor[4]
                        },
                        headerStyle: {
                          fontWeight: 'bold'
                        },
                        getFooterProps: () => ({
                          style: { fontWeight: 'bold' }
                        })
                      },
                      {
                        Header: 'Clicks',
                        accessor: 'clicks',
                        sortable: false,
                        filterable: false,
                        Cell: ({ row }) => {
                          return row.clicks ? numberWithCommas(row.clicks) : 0
                        },
                        Footer: info => {
                          return numberWithCommas(
                            info.data.reduce(
                              (sum, el) =>
                                el.clicks ? (sum += el.clicks) : (sum += 0),
                              0
                            ) || 0
                          )
                        },
                        maxWidth: 100,
                        style: {
                          color: infoColor[4]
                        },
                        headerStyle: {
                          fontWeight: 'bold'
                        },
                        getFooterProps: () => ({
                          style: { fontWeight: 'bold' }
                        })
                      },
                      {
                        Header: 'CTR',
                        accessor: 'ctr',
                        sortable: false,
                        filterable: false,
                        style: {
                          textAlign: 'right'
                        },
                        Cell: ({ row }) => {
                          return isNaN((row.clicks / row.prints).toFixed(3))
                            ? 0 + '%'
                            : (row.clicks / row.prints).toFixed(3) + '%'
                        },
                        maxWidth: 100,
                        headerStyle: {
                          fontWeight: 'bold'
                        }
                      }
                    ]
                  : [
                      {
                        Header: 'Date',
                        accessor: 'date',
                        sortable: true,
                        style: {
                          fontWeight: 'bold'
                        },
                        maxWidth: 100,
                        Cell: ({ row }) => {
                          return moment(row.date.toString()).format(
                            'DD-MM-YYYY'
                          )
                        },
                        headerStyle: {
                          fontWeight: 'bold'
                        }
                      },
                      {
                        Header: 'Provider',
                        accessor: 'provider',
                        maxWidth: 120,
                        headerStyle: {
                          fontWeight: 'bold'
                        }
                      },
                      {
                        Header: 'Source',
                        accessor: 'source',
                        maxWidth: 100,
                        style: {
                          color: roseColor[4]
                        },
                        headerStyle: {
                          fontWeight: 'bold'
                        }
                      },
                      {
                        Header: 'Format',
                        accessor: 'format',
                        maxWidth: 100,
                        headerStyle: {
                          fontWeight: 'bold'
                        }
                      },
                      {
                        Header: 'Platform',
                        accessor: 'platform',
                        maxWidth: 100,
                        headerStyle: {
                          fontWeight: 'bold'
                        }
                      },
                      {
                        Header: 'Prints',
                        accessor: 'prints',
                        sortable: false,
                        filterable: false,
                        Cell: ({ row }) => {
                          return row.prints ? numberWithCommas(row.prints) : 0
                        },
                        Footer: info => {
                          let sum = 0
                          props.tableData.map(el => {
                            return el.prints ? (sum += el.prints) : (sum += 0)
                          })

                          return numberWithCommas(sum)
                        },
                        maxWidth: 100,
                        style: {
                          color: infoColor[4]
                        },
                        headerStyle: {
                          fontWeight: 'bold'
                        },
                        getFooterProps: () => ({
                          style: { fontWeight: 'bold' }
                        })
                      },
                      {
                        Header: 'Clicks',
                        accessor: 'clicks',
                        sortable: false,
                        filterable: false,
                        Cell: ({ row }) => {
                          return row.clicks ? numberWithCommas(row.clicks) : 0
                        },
                        Footer: info => {
                          let sum = 0
                          props.tableData.map(el => {
                            return el.clicks ? (sum += el.clicks) : (sum += 0)
                          })
                          return numberWithCommas(sum)
                        },
                        maxWidth: 100,
                        style: {
                          color: infoColor[4]
                        },
                        headerStyle: {
                          fontWeight: 'bold'
                        },
                        getFooterProps: () => ({
                          style: { fontWeight: 'bold' }
                        })
                      },
                      {
                        Header: 'CTR',
                        accessor: 'ctr',
                        sortable: false,
                        filterable: false,
                        style: {
                          textAlign: 'right'
                        },
                        Cell: ({ row }) => {
                          return isNaN((row.clicks / row.prints).toFixed(3))
                            ? 0 + '%'
                            : (row.clicks / row.prints).toFixed(3) + '%'
                        },
                        maxWidth: 100,
                        headerStyle: {
                          fontWeight: 'bold'
                        }
                      }
                    ]
              }
              defaultPageSize={10}
              showPaginationBottom
              className='-striped -highlight'
              style={{ zIndex: 0 }}
            />
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  )
}
export default CustomTable
