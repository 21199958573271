import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles'

// core components
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import Button from 'components/CustomButtons/Button'
import Card from 'components/Card/Card'
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import CardFooter from 'components/Card/CardFooter.js'
import CustomInput from 'components/CustomInput/CustomInput'

// Firebase
import firebase from '../../firebase/Firebase'
// Formik
import { Formik } from 'formik'
import * as Yup from 'yup'

import styles from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.js'

const useStyles = makeStyles(styles)

export default function LoginPage () {
  const history = useHistory()
  const [loginError, setLoginError] = useState('')
  const [cardAnimaton, setCardAnimation] = React.useState('cardHidden')
  setTimeout(function () {
    setCardAnimation('')
  }, 700)
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <GridContainer justify='center'>
        <GridItem xs={12} sm={6} md={4}>
          <Card login className={classes[cardAnimaton]}>
            <CardHeader
              className={`${classes.cardHeader} ${classes.textCenter}`}
              color='primary'
            >
              <h4 className={classes.cardTitle}>Login</h4>
            </CardHeader>
            <CardBody>
              <Formik
                initialValues={{ email: '', password: '' }}
                onSubmit={async (values, { setSubmitting }) => {
                  const { email, password } = values
                  try {
                    await firebase
                      .auth()
                      .signInWithEmailAndPassword(email, password)
                    history.push('/')
                  } catch (error) {
                    setLoginError(
                      'Email or password not valid, please try again'
                    )
                    console.log('Login error', error)
                  }
                  setSubmitting(false)
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email('Email must be valid')
                    .required('No email provided.'),
                  password: Yup.string()
                    .required('No password provided.')
                    .min(
                      6,
                      'Password is too short - should be 6 chars minimum.'
                    )
                })}
              >
                {formikProps => {
                  const {
                    values,
                    touched,
                    errors,
                    handleSubmit,
                    setFieldValue
                  } = formikProps
                  return (
                    <form onSubmit={handleSubmit}>
                      <CustomInput
                        labelText='Email'
                        id='email'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          name: 'email',
                          value: values.email,
                          onChange: e => {
                            e.preventDefault()
                            setLoginError('')
                            setFieldValue('email', e.target.value)
                          }
                        }}
                      />
                      {errors.email && touched.email ? (
                        <div className={classes.errors}>{errors.email}</div>
                      ) : null}
                      <CustomInput
                        labelText='Password'
                        id='password'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          name: 'password',
                          type: 'password',
                          value: values.password,
                          onChange: e => {
                            e.preventDefault()
                            setLoginError('')
                            setFieldValue('password', e.target.value)
                          }
                        }}
                      />
                      {errors.password && touched.password ? (
                        <div className={classes.errors}>{errors.password}</div>
                      ) : null}
                      {loginError ? (
                        <div className={classes.errors}>{loginError}</div>
                      ) : null}
                      <Button
                        color='primary'
                        type='submit'
                        simple
                        size='lg'
                        block
                      >
                        LOGIN
                      </Button>
                    </form>
                  )
                }}
              </Formik>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}></CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  )
}
